import { useEffect } from "react";
import { useAuthContext } from "../../../context/AmplifyContext";
import useIsSwapAgency from "../../hooks/useIsSwapAgency";

function Intercom() {
  const { userAttributes } = useAuthContext();
  const isSwapAgency = useIsSwapAgency();

  useEffect(() => {
    if (userAttributes) {
      (window as any).intercomSettings = {
        app_id: "o0gfe6b5",
        email: userAttributes.email,
        hide_default_launcher: isSwapAgency, // hide intercom launcher for swap agency
        // cutomise installation with your attributes
      };
    }
  }, [isSwapAgency, userAttributes]);

  return null;
}

export default Intercom;
