const ENDPOINTS = {
  cost_settings: {
    costSettings: ({
      storeId,
      page,
      limit,
      name,
      Export,
      status,
    }: {
      storeId: string;
      page: number;
      limit: number;
      name: string;
      Export?: string;
      status: string;
    }) =>
      `/api/store/settings/cogs?storeId=${storeId}&page=${page}&limit=${limit}&name=${name}&Export=${Export}
      &status=${status}`,
  },
  updateCostSettings: () => `/api/store/settings/cogs`,
  export_cogs: {
    exportCogs: ({ storeId }: { storeId: string }) =>
      `/api/store/settings/export?storeId=${storeId}`,
  },
  transaction_fee: {
    transactionFee: ({ storeId }: { storeId: string }) =>
      `/api/store/settings/transactionFee?storeId=${storeId}`,
  },
  regionsByCountry: {
    regionsByCountry: ({ storeId, country }: { storeId: string; country: string }) =>
      `/api/store/settings/getRegionsByCountry?storeId=${storeId}&country=${country}`,
  },
  getGPMargin: (storeId: string) => `/api/store/settings/gp-margin?storeId=${storeId}`,
  storeGPMargin: () => `/api/store/settings/gp-margin`,
  getStaffCostsSelectedOption: (storeId: string) =>
    `/api/store/settings/staff-costs/staff-costs-selected-option?storeId=${storeId}`,
  storeStaffCostsSelectedOption: () =>
    `/api/store/settings/staff-costs/staff-costs-selected-option`,
  getStaffCostsByMonth: (storeId: string) =>
    `/api/store/settings/staff-costs/staff-costs-by-month?storeId=${storeId}`,
  storeStaffCostsByMonth: () => `/api/store/settings/staff-costs/staff-costs-by-month`,
  getStaffCostsByMember: (storeId: string) =>
    `/api/store/settings/staff-costs/staff-costs-by-member?storeId=${storeId}`,
  staffCostsByMember: () => `/api/store/settings/staff-costs/staff-costs-by-member`,
  getDefaultShippingMargin: (storeId: string) =>
    `/api/store/settings/shipping/default-margin?storeId=${storeId}`,
  storeDefaultShippingMargin: () => `/api/store/settings/shipping/default-margin`,
  getShippingCostsByCountry: (storeId: string) =>
    `/api/store/settings/shipping/shipping-costs-by-country?storeId=${storeId}`,
  shippingCostsByCountry: () => `/api/store/settings/shipping/shipping-costs-by-country`,
  getShippingCostsByOrder: ({
    storeId,
    page,
    limit,
  }: {
    storeId: string;
    page: number;
    limit: number;
  }) =>
    `/api/store/settings/shipping/costs-by-order?storeId=${storeId}&page=${page}&limit=${limit}`,
  importShippingCostsByCountry: () =>
    `/api/store/settings/shipping/import-shipping-costs-by-country`,
  shippingCostsByOrder: () => `/api/store/settings/shipping/costs-by-order`,
  importShippingCostsByOrder: () => `/api/store/settings/shipping/costs-by-order/import`,
  getFullfillmentCosts: (storeId: string) => `/api/store/settings/fulfillment?storeId=${storeId}`,
  storeFulfillmentCosts: () => `/api/store/settings/fulfillment`,
  getTransactionFees: (storeId: string) =>
    `/api/store/settings/transaction-fees?storeId=${storeId}`,
  transactionFees: () => `/api/store/settings/transaction-fees`,
  getFixedExpenses: (storeId: string) =>
    `/api/store/settings/other-costs/fixed-expenses?storeId=${storeId}`,
  fixedExpenses: () => `/api/store/settings/other-costs/fixed-expenses`,
  getVariableExpenses: (storeId: string) =>
    `/api/store/settings/other-costs/variable-expenses?storeId=${storeId}`,
  variableExpenses: () => `/api/store/settings/other-costs/variable-expenses`,
  importFixedExpenses: () => `/api/store/settings/other-costs/import-fixed-expenses`,
  getDefaultsCostsSettings: (storeId: string) => `/api/store/settings/defaults?storeId=${storeId}`,
  storeDefaultsCostsSettings: () => `/api/store/settings/defaults`,
};

const QUERY_KEYS = {
  GP_MARGIN: "gpMargin",
  STAFF_COSTS_SELECTED_OPTION: "staffCostsSelectedOption",
  STAFF_COSTS_BY_MONTH: "staffCostsByMonth",
  STAFF_COSTS_BY_MEMBER: "staffCostsByMember",
  DEFAULT_SHIPPING_MARGIN: "defaultShippingMargin",
  SHIPPING_COSTS_BY_COUNTRY: "shippingCostsByCountry",
  SHIPPING_COSTS_BY_ORDER: "shippingCostsByOrder",
  FULFILLMENT_COSTS: "fulfillmentCosts",
  TRANSACTION_FEES: "transactionFees",
  FIXED_EXPENSES: "fixedExpenses",
  VARIABLE_EXPENSES: "variableExpenses",
  DEFAULTS_COSTS_SETTINGS: "defaultsCostsSettings",
};

export { ENDPOINTS, QUERY_KEYS };
