import { queryFetcher } from './index';

const ENDPOINTS = {
  profile: () => '/api/profile',
  updateProfile: () => '/api/profile/update',
  updateInfo: () => '/api/profile/info',
};

function updateNameAndEmail<T>(payload: T) {
  return queryFetcher(ENDPOINTS.updateInfo(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export { ENDPOINTS, updateNameAndEmail };
