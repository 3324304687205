import { createFilteredUrlParams } from "../utils/createFilteredUrlParams";
import {
  IAllRegionsByCountryListRequest,
  IMarketsRequest,
} from "../pages/Settings/modules/Cost/modules/Markets/hooks/useOperateMarkets";

const markets = "/api/market-cost-settings";
const ENDPOINTS = {
  allMarketsList: (params: IMarketsRequest) =>
    `${markets}?${createFilteredUrlParams<IMarketsRequest>(params)}`,
  allCountriesList: (params: IMarketsRequest) =>
    `${markets}/countries?${createFilteredUrlParams<IMarketsRequest>(params)}`,
  allRegionsByCountryList: (params: IAllRegionsByCountryListRequest) =>
    `${markets}/regions?${createFilteredUrlParams<IAllRegionsByCountryListRequest>(params)}`,
  createUpdateDeleteMarkets: () => `${markets}`,
};

export { ENDPOINTS };
