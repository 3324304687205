import React from "react";
import { useRouter } from "next/router";
import { ConnectionServices } from "../src/components/ServiceConnectionButtons";
import { useStoresContext } from "./StoresContext";
import { getErrorMessage } from "../src/utils/common";

type ResponseErrorCheckParams = {
  connectionServices?: ConnectionServices[];
  unauthorised?: boolean;
  noPermissions?: boolean;
  isFromDashboardCards?: boolean;
};

type ErrorContextType = {
  productNotReady: {
    isProductNotReady: boolean;
  };
  invalidApiKey: ConnectionServices[] | null;
  unauthorised: ConnectionServices[] | null;
  noPermissions: ConnectionServices[] | null;
  handleResponseErrorCheck: (response: any, params?: ResponseErrorCheckParams) => void;
  unknownError: boolean | string;
  isDashboardCardError: boolean;
};

type ErrorContextProps = {
  children: React.ReactNode;
};

export const PRODUCT_NOT_READY_ERROR = "PRODUCT_NOT_READY";
export const UNAUTHORISED = "Unauthorized";
export const INVALID_API_KEY = "INVALID_API_KEY";
export const NO_PERMISSIONS = "User does not have sufficient permissions for this profile.";

const ErrorContext = React.createContext<ErrorContextType | undefined>(undefined);

function ErrorProvider(props: ErrorContextProps) {
  const [isDashboardCardError, setIsDashboardCardError] = React.useState<boolean>(false);
  const [isProductNotReady, setIsProductNotReady] = React.useState<boolean>(false);
  const [unauthorised, setUnauthorised] = React.useState<ConnectionServices[] | null>(null);
  const [invalidApiKey, setInvalidApiKey] = React.useState<ConnectionServices[] | null>(null);
  const [unknownError, setUnknownError] = React.useState<boolean | string>(false);
  const [noPermissions, setNoPermissions] = React.useState<ConnectionServices[] | null>(null);

  const { selectedStore } = useStoresContext();
  const { asPath } = useRouter();

  React.useEffect(() => {
    setIsDashboardCardError(false);
    setUnknownError(false);
    setUnauthorised(null);
    setIsProductNotReady(false);
    setInvalidApiKey(null);
    setNoPermissions(null);
  }, [selectedStore?.id, asPath]);

  const handleResponseErrorCheck = React.useCallback(
    (response: any, params?: ResponseErrorCheckParams) => {
      const errorMessage = getErrorMessage(response)?.trim?.();

      if (errorMessage === PRODUCT_NOT_READY_ERROR) {
        if (params?.isFromDashboardCards) {
          setIsDashboardCardError(true);
          return;
        }
        setIsProductNotReady(true);
        return;
      }
      if (isProductNotReady) {
        setIsProductNotReady(false);
      }

      if (errorMessage === UNAUTHORISED && params?.unauthorised) {
        if (params!.connectionServices) {
          setUnauthorised(params!.connectionServices);
        }
        return;
      }
      if (unauthorised) {
        setUnauthorised(null);
      }

      if (
        errorMessage === INVALID_API_KEY &&
        params?.connectionServices &&
        Array.isArray(params?.connectionServices)
      ) {
        return setInvalidApiKey(params.connectionServices);
      }
      if (invalidApiKey) {
        setInvalidApiKey(null);
      }

      if (errorMessage === NO_PERMISSIONS && params?.noPermissions) {
        if (params!.connectionServices) {
          return setNoPermissions(params!.connectionServices);
        }
      }
      if (noPermissions) {
        setNoPermissions(null);
      }

      if (errorMessage) {
        setUnknownError(response);
      }
      if (unknownError) {
        setUnknownError(false);
      }
    },
    [invalidApiKey, isProductNotReady, noPermissions, unauthorised, unknownError],
  );

  const value = React.useMemo(
    () => ({
      productNotReady: { isProductNotReady },
      unauthorised,
      handleResponseErrorCheck,
      invalidApiKey,
      unknownError,
      noPermissions,
      isDashboardCardError,
    }),
    [
      handleResponseErrorCheck,
      invalidApiKey,
      isProductNotReady,
      unauthorised,
      unknownError,
      noPermissions,
      isDashboardCardError,
    ],
  );
  return <ErrorContext.Provider value={value} {...props} />;
}

const useErrorContext = (): ErrorContextType => {
  const context = React.useContext(ErrorContext);
  if (context === undefined) {
    throw new Error("useErrorContext must be used within a ErrorProvider");
  }
  return context;
};

export { ErrorProvider, useErrorContext };
