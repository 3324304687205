const ENDPOINTS = {
  getShipStation: (storeId: string) => `/api/shipping/shipstation?storeId=${storeId}`,
  shipStation: "/api/shipping/shipstation",
  getShipBob: (storeId: string) => `/api/shipping/shipbob?storeId=${storeId}`,
  shipBob: "/api/shipping/shipbob",
};

const QUERY_KEYS = {
  SHIP_STATION: "shipStation",
  SHIP_BOB: "shipBob",
};

export { ENDPOINTS, QUERY_KEYS };
