import { ServerErrorCodes } from "../../shared/serverErrors/customErrors";
import { CustomError, ErrorType } from "../errorTypes";
import { checkRateLimit } from "../facebook/utils";

export async function makeCustomAPIError(error: unknown, fbAccessToken?: string) {
  let message = "Something went wrong";
  if (error instanceof Error) message = error.message;
  else message = String(error);
  const standardError = {
    customError: {
      errorType: ErrorType.API_ERROR,
      message,
    },
  };
  const isRateLimitError = message === FBRateLimitExceededErrorMessage;
  if (isRateLimitError) {
    const rateLimitError = await checkRateLimit(fbAccessToken || "");
    return rateLimitError || standardError;
  }
  return standardError;
}

export const FBAdsErrors = {
  fbAdsNotConnectedError: {
    customError: {
      errorType: ErrorType.NO_TOKEN,
      message: "Meta ads account is not connected",
    },
  },
  fbRateLimitError: (minsToRegainAccess: number) => ({
    customError: {
      errorType: ErrorType.RATE_LIMIT,
      message: `Rate limit exceeded. Please try again in ${minsToRegainAccess} mins`,
    },
  }),
};

export function isFbTokenWithError(
  token: string | null | { customError: CustomError },
): token is { customError: CustomError } {
  return token !== null && typeof token !== "string" && "customError" in token;
}

export const FBRateLimitExceededErrorMessage = "Rate limit exceeded";

export const productNotReadyError = {
  customError: {
    errorType: ErrorType.PRODUCT_NOT_READY,
    message: ServerErrorCodes.PRODUCT_NOT_READY_ERROR,
  },
};

export function makeCustomDBError(error: unknown) {
  let message = "DB - Something went wrong";
  if (error instanceof Error) message = error.message;
  else message = String(error);
  const standardError = {
    customError: {
      errorType: ErrorType.DB_ERROR,
      message,
    },
  };

  return standardError;
}
