import db from "../aws/dynamodb";
import { ONBOARDING_CHECKLIST_TABLE_NAME } from "../constants";

export enum OnboardingChecklistItemManualOverride {
  BasicCompleted = "BasicCompletedManualOverride",
  ProductCostsCompleted = "ProductCostsCompletedManualOverride",
  ShippingCostsCompleted = "ShippingCostsCompletedManualOverride",
  FulfillmentCostsCompleted = "FulfillmentCostsCompletedManualOverride",
  TransactionFeesCompleted = "TransactionFeesCompletedManualOverride",
}

export type OnboardingChecklistItem = {
  storeId: string;
  areBasicsCompleted: boolean;
  areProductCostsCompleted: boolean;
  areShippingCostsCompleted: boolean;
  areFulfillmentCostsCompleted: boolean;
  areTransactionFeesCompleted: boolean;
  areAllMarkedAsComplete?: boolean;
  manualOverrides?: Record<OnboardingChecklistItemManualOverride, boolean>;
};

export async function getOnboardingChecklistItem(storeId: string) {
  try {
    const { Item } = await db.get({
      TableName: ONBOARDING_CHECKLIST_TABLE_NAME,
      Key: {
        storeId,
      },
    });
    return Item as OnboardingChecklistItem | undefined;
  } catch (e) {
    console.log("ERROR GETTING ONBOARDING CHECKLIST ITEM", e);
    throw new Error("Error getting onboarding checklist item");
  }
}

export async function storeOnboardingChecklistItem(item: OnboardingChecklistItem) {
  try {
    await db.update({
      TableName: ONBOARDING_CHECKLIST_TABLE_NAME,
      Key: {
        storeId: item.storeId,
      },
      UpdateExpression:
        "set lastCheckAt = :lastCheckAt, manualOverrides = if_not_exists(manualOverrides, :manualOverrides)",
      ExpressionAttributeValues: {
        ":lastCheckAt": new Date().toISOString(),
        ":manualOverrides": {},
      },
    });
  } catch (e) {
    console.log("ERROR UPDATING ONBOARDING CHECKLIST ITEM", e);
    throw new Error("Error updating onboarding checklist item");
  }
}

export type UpateAllMarkedAsCompleteArgs = {
  storeId: string;
  areAllMarkedAsComplete: boolean;
};

export async function updateAllMarkedAsComplete({
  storeId,
  areAllMarkedAsComplete,
}: UpateAllMarkedAsCompleteArgs) {
  try {
    await db.update({
      TableName: ONBOARDING_CHECKLIST_TABLE_NAME,
      Key: {
        storeId,
      },
      UpdateExpression:
        "set areAllMarkedAsComplete = :areAllMarkedAsComplete, allMarkedAsCompleteUpdatedAt = :allMarkedAsCompleteAt",
      ExpressionAttributeValues: {
        ":areAllMarkedAsComplete": areAllMarkedAsComplete,
        ":allMarkedAsCompleteAt": new Date().toISOString(),
      },
    });
  } catch (e) {
    console.log("ERROR UPDATING ONBOARDING CHECKLIST ITEM", e);
    throw new Error("Error updating onboarding checklist item");
  }
}

export type UpdateOnboardingChecklistItemManualOverrideArgs = {
  storeId: string;
  item: OnboardingChecklistItemManualOverride;
  value: boolean;
};

export async function updateOnboardingChecklistItemManualOverride({
  storeId,
  item,
  value,
}: UpdateOnboardingChecklistItemManualOverrideArgs) {
  console.log("PARAMS:", { storeId, item, value });
  try {
    await db.update({
      TableName: ONBOARDING_CHECKLIST_TABLE_NAME,
      Key: {
        storeId,
      },
      UpdateExpression:
        "set #manualOverrides.#item = :value, areAllMarkedAsComplete = :allComplete",
      ExpressionAttributeNames: {
        "#item": item,
        "#manualOverrides": "manualOverrides",
      },
      ExpressionAttributeValues: {
        ":value": value,
        ":allComplete": false, // Setting areAllMarkedAsComplete to false
      },
    });
  } catch (e) {
    console.log("ERROR UPDATING ONBOARDING CHECKLIST ITEM", e);
    throw new Error("Error updating onboarding checklist item");
  }
}
