// import { API, graphqlOperation } from 'aws-amplify';
import { generateClient } from "aws-amplify/api";
import * as queries from "../graphql/queries";
import { AppSyncOnboardingOptionsItem } from "../../types";
import { resolver } from "./index";

const client = generateClient();

const ENDPOINTS = {
  accounts: (storeId?: string) => `/api/analytics/accounts?storeId=${storeId}`,
  properties: ({ accountId, storeId }: { accountId?: string; storeId?: string }) =>
    `/api/analytics/properties?accountId=${accountId}&storeId=${storeId}`,
  views: ({
    accountId,
    propertyID,
    storeId,
  }: {
    accountId?: string;
    propertyID?: string;
    storeId?: string;
  }) =>
    `/api/analytics/views?accountId=${accountId}&webPropertyId=${propertyID}&storeId=${storeId}`,
};

function getAccounts() {
  return resolver(ENDPOINTS.accounts());
}

function getProperties(accountId: string) {
  return resolver(ENDPOINTS.properties({ accountId }));
}

async function getIndustries(variables?: {}, authToken?: string) {
  return client.graphql({ query: queries.listIndustries, variables, authToken }) as Promise<{
    data: {
      listIndustries: {
        items: AppSyncOnboardingOptionsItem[];
      };
    };
  }>;
}

function getCurrencies(variables?: {}, authToken?: string) {
  return client.graphql({ query: queries.listCurrencies, variables, authToken }) as Promise<{
    data: {
      listCurrencies: {
        items: AppSyncOnboardingOptionsItem[];
      };
    };
  }>;
}

function getRoles(variables?: {}, authToken?: string) {
  return client.graphql({ query: queries.listRoles, variables, authToken }) as Promise<{
    data: {
      listRoles: {
        items: AppSyncOnboardingOptionsItem[];
      };
    };
  }>;
}

export { getIndustries, getCurrencies, getRoles, getAccounts, getProperties, ENDPOINTS };
