export type FinanceCardsRequestParams = {
  startDate: string;
  endDate: string;
  comparisonStartDate?: string;
  comparisonEndDate?: string;
  storeId: string | undefined;
  customerId: string | undefined;
  accountId: string | undefined;
  managerCustomerId: string | undefined;
  advertiserId?: number | undefined;
  baseCurrency?: string;
  targetCurrency?: string;
};
export type FinanceChartsRequestParams = {
  startDate: string;
  endDate: string;
  comparisonStartDate?: string;
  comparisonEndDate?: string;
  storeId: string | undefined;
  customerId: string | undefined;
  accountId: string | undefined;
  managerCustomerId: string | undefined;
  advertiserId?: number | undefined;
  filter?: string | undefined;
  baseCurrency?: string;
  targetCurrency?: string;
};
export const EndpointKeys = {
  cards: "cards",
  charts: "charts",
  tables: "tables",
};

const ENDPOINTS: any = {
  [EndpointKeys.cards]: {
    dataForPeriod: ({
      storeId,
      startDate,
      endDate,
      comparisonEndDate,
      comparisonStartDate,
      managerCustomerId,
      customerId,
      accountId,
      advertiserId,
      baseCurrency,
      targetCurrency,
    }: FinanceCardsRequestParams) =>
      storeId
        ? `/api/finance/cards?storeId=${storeId}&managerCustomerId=${managerCustomerId}&customerId=${customerId}&accountId=${accountId}&baseCurrency=${baseCurrency}&targetCurrency=${targetCurrency}${
            advertiserId ? `&advertiserId=${advertiserId}` : ""
          }&startDate=${startDate}&endDate=${endDate}${
            comparisonStartDate && comparisonEndDate
              ? `&prevStartDate=${comparisonStartDate}&prevEndDate=${comparisonEndDate}`
              : ""
          }`
        : "",
  },
  [EndpointKeys.charts]: {
    dataForPeriod: ({
      storeId,
      startDate,
      endDate,
      comparisonEndDate,
      comparisonStartDate,
      managerCustomerId,
      customerId,
      accountId,
      advertiserId,
      filter,
    }: FinanceChartsRequestParams) =>
      storeId
        ? `/api/finance/charts?storeId=${storeId}&managerCustomerId=${managerCustomerId}&customerId=${customerId}&accountId=${accountId}${
            advertiserId ? `&advertiserId=${advertiserId}` : ""
          }&startDate=${startDate}&endDate=${endDate}${
            comparisonStartDate && comparisonEndDate
              ? `&prevStartDate=${comparisonStartDate}&prevEndDate=${comparisonEndDate}`
              : ""
          }&filter=${filter}`
        : "",
  },
  [EndpointKeys.tables]: {
    dataForPeriod: ({
      storeId,
      startDate,
      endDate,
      comparisonEndDate,
      comparisonStartDate,
      managerCustomerId,
      customerId,
      accountId,
      advertiserId,
      filter,
      baseCurrency,
      targetCurrency,
    }: FinanceChartsRequestParams) =>
      storeId
        ? `/api/finance/tables?storeId=${storeId}&managerCustomerId=${managerCustomerId}&customerId=${customerId}&accountId=${accountId}&baseCurrency=${baseCurrency}&targetCurrency=${targetCurrency}${
            advertiserId ? `&advertiserId=${advertiserId}` : ""
          }&startDate=${startDate}&endDate=${endDate}${
            comparisonStartDate && comparisonEndDate
              ? `&prevStartDate=${comparisonStartDate}&prevEndDate=${comparisonEndDate}`
              : ""
          }&filter=${filter}`
        : "",
  },
};

export { ENDPOINTS };
