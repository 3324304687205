interface ICardsParams {
  customerId: string;
  from: string;
  to: string;
  managerCustomerId: string;
  cards: string;
  prevStartDate?: string;
  prevEndDate?: string;
  storeId?: string;
}

interface IChartParams {
  customerId: string;
  from: string;
  to: string;
  managerCustomerId: string;
  prevStartDate?: string;
  prevEndDate?: string;
  storeId?: string;
}

interface IPerformanceByTypeParams {
  customerId: string;
  from: string;
  to: string;
  managerCustomerId: string;
  storeId?: string;
}

interface IPerformanceParams {
  customerId: string;
  from: string;
  to: string;
  managerCustomerId: string;
  storeId?: string;
}

interface IPieChartsParams {
  customerId: string;
  from: string;
  to: string;
  managerCustomerId: string;
  storeId?: string;
}

export const EndpointKeys = {
  cards: "cards",
  charts: "charts",
  "performance-by-type": "performance-by-type",
  performance: "performance",
  "pie-charts": "pie-charts",
};

const ENDPOINTS: Record<string, Record<string, any> | any> = {
  [EndpointKeys.cards]: {
    dataForPeriod: ({
      customerId,
      from,
      to,
      managerCustomerId,
      cards,
      prevStartDate,
      prevEndDate,
      storeId,
    }: ICardsParams) =>
      `/api/google-ads/cards?storeId=${storeId}&customerId=${customerId}&startDate=${from}&endDate=${to}&managerCustomerId=${managerCustomerId}&cards=${cards}${
        prevStartDate && prevEndDate
          ? `&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`
          : ""
      }`,
  },
  [EndpointKeys.charts]: {
    dataForPeriod: ({
      customerId,
      from,
      to,
      managerCustomerId,
      prevStartDate,
      prevEndDate,
      storeId,
    }: IChartParams) =>
      `/api/google-ads/charts?storeId=${storeId}&customerId=${customerId}&startDate=${from}&endDate=${to}&managerCustomerId=${managerCustomerId}${
        prevStartDate && prevEndDate
          ? `&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`
          : ""
      }`,
  },
  [EndpointKeys["performance-by-type"]]: {
    dataForPeriod: ({
      customerId,
      from,
      to,
      managerCustomerId,
      storeId,
    }: IPerformanceByTypeParams) =>
      `/api/google-ads/performance-by-type?storeId=${storeId}&customerId=${customerId}&startDate=${from}&endDate=${to}&managerCustomerId=${managerCustomerId}`,
  },
  [EndpointKeys.performance]: {
    dataForPeriod: ({ customerId, from, to, managerCustomerId, storeId }: IPerformanceParams) =>
      `/api/google-ads/performance?storeId=${storeId}&customerId=${customerId}&startDate=${from}&endDate=${to}&managerCustomerId=${managerCustomerId}`,
  },
  [EndpointKeys["pie-charts"]]: {
    dataForPeriod: ({ customerId, from, to, managerCustomerId, storeId }: IPieChartsParams) =>
      `/api/google-ads/pie-charts?storeId=${storeId}&customerId=${customerId}&startDate=${from}&endDate=${to}&managerCustomerId=${managerCustomerId}`,
  },
  customers: (storeId: string | undefined) => `/api/google-ads/customers?storeId=${storeId}`,
};

export { ENDPOINTS };
