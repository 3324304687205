import { CognitoIdentityProvider } from "@aws-sdk/client-cognito-identity-provider";

const CognitoClient = new CognitoIdentityProvider({
  apiVersion: "latest",
  credentials: {
    accessKeyId: process.env.AWS_COGNITO_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.AWS_COGNITO_SECRET_ACCESS_KEY as string,
  },
});

export default CognitoClient;
