export enum ErrorType {
  "PARAM_MISSING" = "PARAM_MISSING",
  "API_ERROR" = "API_ERROR",
  "ACCESS_TOKEN_EXPIRED" = "ACCESS_TOKEN_EXPIRED",
  "NO_TOKEN" = "NO_TOKEN",
  "RATE_LIMIT" = "RATE_LIMIT",
  "PRODUCT_NOT_READY" = "PRODUCT_NOT_READY",
  "API_KEY_MISSING" = "API_KEY_MISSING",
  "METHOD_NOT_ALLOWED" = "METHOD_NOT_ALLOWED",
  "DB_ERROR" = "DB_ERROR",
  "INVITE_NOT_FOUND" = "INVITE_NOT_FOUND",
  "NOT_AUTHORIZED" = "NOT_AUTHORIZED",
}

export type CustomError = {
  errorType: ErrorType;
  message?: string;
};

export type ServerError = {
  customError: CustomError;
};

export class CustomServerError extends Error {
  errorType: ErrorType;

  constructor(errorType: ErrorType, message?: string) {
    super(message);
    this.errorType = errorType;
  }
}
