import React from "react";
import { useQuery, useQueryClient } from "react-query";
import type { Store } from "../types";
import api, { queryFetcher } from "../src/APIResolvers";
import { useAuthContext } from "./AmplifyContext";
import { listStoresByUserId } from "../lib/db/store-table";
import { ENDPOINTS } from "../src/APIResolvers/metaAdsCurrencySettings";
import { usePermissions } from "./usePermissions";

type StoresContextValue = {
  selectStore: (store: string | Store, isOnboarding?: boolean) => void;
  updateStore: (
    payload: Omit<Partial<Store>, "currency" | "industry" | "role"> & {
      storeCurrencyId?: string;
      storeRoleId?: string;
      storeIndustryId?: string;
    },
  ) => void;
  selectedStore: Store | null;
  stores: Store[];
  isStoresLoading: boolean;
};

const StoresContext = React.createContext<StoresContextValue>({
  selectStore: () => {},
  updateStore: () => {},
  selectedStore: null,
  stores: [],
  isStoresLoading: true,
});

function StoresProvider(props: any) {
  const queryClient = useQueryClient();
  const { authedUser, authedUserLoading } = useAuthContext();
  const [selectedStore, setSelectedStore] = React.useState<Store | null>(null);
  const [isStoresLoading, setIsStoresLoading] = React.useState<boolean>(false);
  usePermissions(selectedStore); // check if user has permissions and redirect if not
  const getStores = () => queryFetcher(`/api/store/list-stores?userId=${authedUser?.username}`);
  const {
    data: stores = [],
    error,
    isLoading = true,
  } = useQuery<Store[]>(["list-stores", authedUser?.username], getStores, {
    retry: 1,
    refetchOnWindowFocus: false,
    enabled: !!authedUser,
  });

  const {
    data: selectedCurrencyItem,
    isLoading: isSelectedCurrencyLoading,
    refetch: refetchCurrencySetting,
  } = useQuery(
    ["get-currency-settings", { storeId: selectedStore?.id as string }] as const,
    ({ queryKey }) => queryFetcher(ENDPOINTS.fetchAdsCurrencySettingByStoreID(queryKey[1])),
    {
      retry: true,
      enabled: !!selectedStore?.id, // Only fetch when store is selected
    },
  );

  React.useEffect(() => {
    if (window && stores.length) {
      const storeFromLocalStorage = stores.find(
        (store) => store.id === localStorage.getItem("store-id"),
      );

      if (storeFromLocalStorage) {
        setSelectedStore(storeFromLocalStorage);
      } else {
        setSelectedStore(stores[0]);
        localStorage.setItem("store-id", stores[0].id);
      }
    }
  }, [stores]);

  React.useEffect(() => {
    if (selectedCurrencyItem) {
      setSelectedStore((prevStore: any) => ({
        ...prevStore,
        adsCurrencySetting: selectedCurrencyItem,
      }));
    }
  }, [selectedCurrencyItem]);

  const selectStore = React.useCallback(
    async (store: string, isOnboarding: boolean = false) => {
      try {
        setIsStoresLoading(true);

        if (isOnboarding) {
          await queryClient.invalidateQueries("list-stores");
        }

        const tempStores =
          stores && stores?.length > 0 ? stores : await listStoresByUserId(authedUser?.username);
        const storeFromList = (tempStores as Store[])?.find((el) => el?.id === store);
        if (store) {
          localStorage.setItem("store-id", store);
        }
        setSelectedStore(storeFromList || (tempStores?.length ? (tempStores[0] as Store) : null));
        refetchCurrencySetting();
        //TODO: remove extra rerender: replace invalidateQueries with interacting with cache directly
        setIsStoresLoading(false);
      } catch (e) {
        console.log("error", e);
      }
    },
    [authedUser?.username, queryClient, stores],
  );

  const updateStore = React.useCallback(
    (
      payload: Omit<Partial<Store>, "currency" | "industry" | "role"> & {
        storeCurrencyId?: string;
        storeRoleId?: string;
        storeIndustryId?: string;
      },
    ) =>
      // setIsStoresLoading(true);
      api.store
        .updateStore({
          id: selectedStore?.id,
          storeCurrencyId: payload?.storeCurrencyId || selectedStore?.currency?.id,
          storeIndustryId: payload?.storeIndustryId || selectedStore?.industry?.id,
          ...payload,
        })
        .then((response) => {
          queryClient.invalidateQueries("list-stores");
        }),
    // .finally(() => {
    //   setIsStoresLoading(false);
    // });
    [queryClient, selectedStore?.currency?.id, selectedStore?.id, selectedStore?.industry?.id],
  );

  const value = React.useMemo(
    () => ({
      stores,
      selectedStore,
      //todo: remove authedUserLoading prop
      isStoresLoading: isStoresLoading || isLoading || authedUserLoading,
      selectStore,
      updateStore,
    }),
    [
      stores,
      selectedStore,
      isStoresLoading,
      isLoading,
      authedUserLoading,
      selectStore,
      updateStore,
    ],
  );

  return <StoresContext.Provider value={value} {...props} />;
}

function useStoresContext() {
  const context = React.useContext(StoresContext);
  if (context === undefined) {
    throw new Error("useStoresContext must be used within an StoresProvider");
  }
  return context;
}

export { StoresProvider, useStoresContext, StoresContext };
