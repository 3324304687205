import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  TUpdateOnboardingChecklist,
  UpdateOnboardingChecklistItemManual,
  useOnboardingChecklistQuery,
} from "./useOnboardingChecklist";
import {
  formatOnboardingChecklistData,
  salesChannelTitle,
} from "../../src/components/OnboardingChecklist/utils";
import { useStoresContext } from "../StoresContext";
import { IChecklistItem } from "../../src/components/OnboardingChecklist/types";

interface IOnboardingChecklistContext {
  checklistItems: IChecklistItem[];
  setChecklistItems: Dispatch<SetStateAction<IChecklistItem[]>>;
  filteredChecklistItems: IChecklistItem[];
  setFilteredChecklistItems: (data: IChecklistItem[]) => void;
  onboardingChecklistLoading: boolean;
  addOnboardingAllItemsChecklist: ({ areAllMarkedAsComplete }: TUpdateOnboardingChecklist) => void;
  addOnboardingItemChecklistManualOverride: ({
    item,
    value,
  }: UpdateOnboardingChecklistItemManual) => void;
  isComponentVisible: boolean;
  toggleOnboardingChecklistVisible: () => void;
}

const OnboardingChecklistContext = React.createContext<IOnboardingChecklistContext | undefined>(
  undefined,
);

function OnboardingChecklistContextProvider<T extends object>(
  props: React.PropsWithChildren<T>,
): React.ReactElement {
  const {
    onboardingChecklist,
    onboardingChecklistError,
    onboardingChecklistLoading,
    addOnboardingAllItemsChecklist,
    addOnboardingItemChecklistManualOverride,
  } = useOnboardingChecklistQuery();

  const { selectedStore } = useStoresContext();

  const defaultData = onboardingChecklistLoading
    ? []
    : onboardingChecklist &&
      formatOnboardingChecklistData({
        salesChannel: (selectedStore && salesChannelTitle(selectedStore)) || "Sales Channel",
        fulfilmentCostsSettings: "settings/costs/fulfillment",
        transactionFeesSettings: "settings/costs/transactionFees",
        ...onboardingChecklist,
      });

  const [checklistItems, setChecklistItems] = useState<IChecklistItem[]>(defaultData);
  const [filteredChecklistItems, setFilteredChecklistItems] =
    useState<IChecklistItem[]>(checklistItems);
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(false);

  const hideComponent = () => setIsComponentVisible(false);

  const showComponent = () => setIsComponentVisible(true);

  useEffect(() => setChecklistItems(defaultData), [onboardingChecklist]);

  useEffect(() => {
    if (!checklistItems) return;

    if (checklistItems.filter((item: IChecklistItem) => !item.isCompleted).length === 0)
      hideComponent();
    else showComponent();
  }, [checklistItems]);

  const value = React.useMemo(
    () => ({
      checklistItems,
      setChecklistItems,
      filteredChecklistItems,
      setFilteredChecklistItems,
      onboardingChecklistLoading,
      addOnboardingAllItemsChecklist,
      addOnboardingItemChecklistManualOverride,
      isComponentVisible,
      toggleOnboardingChecklistVisible: () => setIsComponentVisible((prev) => !prev),
    }),
    [
      checklistItems,
      setChecklistItems,
      filteredChecklistItems,
      setFilteredChecklistItems,
      onboardingChecklistLoading,
      addOnboardingAllItemsChecklist,
      addOnboardingItemChecklistManualOverride,
      isComponentVisible,
    ],
  );

  return <OnboardingChecklistContext.Provider value={value} {...props} />;
}

function useOnboardingChecklist() {
  const context = React.useContext(OnboardingChecklistContext);
  if (context === undefined) {
    throw new Error(
      "useOnboardingChecklist must be used within an OnboardingChecklistContextProvider",
    );
  }
  return context;
}

export { OnboardingChecklistContextProvider, useOnboardingChecklist, OnboardingChecklistContext };
