import { resolver } from './index';

type DataForPeriodProps = {
  url: string;
  from: string;
  to: string;
  prevStartDate: string;
  prevEndDate: string;
  storeId: string | undefined;
};

const ENDPOINTS = {
  dataForPeriod: ({
    url,
    from,
    to,
    prevStartDate,
    prevEndDate,
    storeId,
  }: DataForPeriodProps) =>
    `/api/search?storeId=${storeId}&url=${url}&startDate=${from}&endDate=${to}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`,
  sites: (storeId?: string) => `/api/search/sites?storeId=${storeId}`,
};

function getDataForPeriod(payload: DataForPeriodProps) {
  return resolver(ENDPOINTS.dataForPeriod(payload));
}

export { getDataForPeriod, ENDPOINTS };
