const ENDPOINTS = {
  getSlackData: (storeId: string) => `/api/slack?storeId=${storeId}`,
  listChannels: (storeId: string) => `/api/slack/channels?storeId=${storeId}`,
  saveReportOptions: () => `/api/slack`,
  disconnectSlack: () => `/api/slack`,
};

const QUERY_KEYS = {
  SLACK_DATA: "SLACK_DATA",
  SLACK_CHANNELS: "SLACK_CHANNELS",
};

export { ENDPOINTS, QUERY_KEYS };
