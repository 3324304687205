const ENDPOINTS = {
  getTeamMembers: (storeId: string) => `/api/store/settings/team/team-members?storeId=${storeId}`,
  teamMemberActions: () => `/api/store/settings/team/team-member-actions`,
  sendInvite: () => `/api/store/settings/team/send-invite`,
  sendInviteAgency: () => `/api/store/settings/team/agency/agency-send-invite`,
  teamMemberActionsAgency: () => `/api/store/settings/team/agency/agency-team-member-actions`,
  getAgency: (agencyId: string) => `/api/store/settings/team/agency?agencyId=${agencyId}`,
  getAgencyTeamMembers: (agencyId: string) =>
    `/api/store/settings/team/agency/agency-team-members?agencyId=${agencyId}`,
  updateAgencyName: () => `/api/store/settings/team/agency`,
  linkPartner: () => `/api/store/settings/team/link-partner`,
};

const QUERY_KEYS = {
  TEAM_MEMBERS: "TEAM_MEMBERS",
  AGENCY_TEAM_MEMBERS: "AGENCY_TEAM_MEMBERS",
  AGENCY: "AGENCY",
};

export { ENDPOINTS, QUERY_KEYS };
