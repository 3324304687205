const ENDPOINTS = {
  getPaidPlans: () => `/api/paid-plan/list-items`,
  paidPlanActions: () => `/api/paid-plan`,
  getPaidPlan: (storeId: string) => `/api/paid-plan?storeId=${storeId}`,
};

const QUERY_KEYS = {
  PAID_PLANS_AGENCY: "PAID_PLANS_AGENCY",
  PAID_PLAN: "PAID_PLAN",
};

export { ENDPOINTS, QUERY_KEYS };
