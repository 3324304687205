import { kv } from "@vercel/kv";
import { queryFetcher } from ".";
import {
  CurrencySettingItem,
  getCurrencySettingsByStoreId,
} from "../../lib/db/ads-currency-settings";
import { CurrencyItem } from "../../lib/db/meta-ads-currency-settings";
import { getExchageRatesByCurrencyAndDateRange } from "../../lib/facebook/exchangeratesapi";
import { setCacheWithExpiry } from "../../lib/utils";

const ENDPOINTS = {
  updateMetaAdsCurrencySettings: () => "/api/meta-currency-conversion/update-currency-setting",

  updateAdsCurrencySettings: () => "/api/currency-conversion-settings/update-currency-setting",

  fetchAdsCurrencySettingByStoreID: ({ storeId }: { storeId: string }) =>
    `/api/currency-conversion-settings/currency-settings?storeId=${storeId}`,

  // fetchMetaAdsCurrencySettingByStoreID: ({ storeId }: { storeId: string }) =>
  //   `/api/meta-currency-conversion/currency-settings?storeId=${storeId}`,
};

function updatedMetaAdsCurrencySettingItem(props: { storeId: string; currencyItem: CurrencyItem }) {
  return queryFetcher(ENDPOINTS.updateMetaAdsCurrencySettings(), {
    method: "POST",
    body: JSON.stringify(props),
  });
}

function updatedAdsCurrencySettingItem(props: {
  storeId: string;
  currencyItem: CurrencySettingItem;
}) {
  return queryFetcher(ENDPOINTS.updateAdsCurrencySettings(), {
    method: "POST",
    body: JSON.stringify(props),
  });
}

const fetchAdsCurrencySetting = async ({ storeId }: { storeId: string }) => {
  const cacheKey = `currency-setting-ads-${storeId}`;
  const cachedData = await kv.get(cacheKey);

  if (cachedData) {
    return cachedData;
  }

  const result: CurrencySettingItem | any = await getCurrencySettingsByStoreId(storeId as string);
  await setCacheWithExpiry({ cacheKey, data: result, query: "" });

  return result;
};

async function fetchGoogleAdsExchangeRateByDates({
  startDate,
  endDate,
  baseCurrency,
  targetCurrency,
}: {
  startDate: string;
  endDate: string;
  baseCurrency: string;
  targetCurrency: string;
}) {
  const rates = baseCurrency
    ? await getExchageRatesByCurrencyAndDateRange({
        startDate,
        endDate,
        baseCurrency,
        targetCurrency,
      })
    : null;

  return rates;
}

export {
  ENDPOINTS,
  updatedMetaAdsCurrencySettingItem,
  updatedAdsCurrencySettingItem,
  fetchAdsCurrencySetting,
  fetchGoogleAdsExchangeRateByDates,
};
