/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCurrency = /* GraphQL */ `
  query GetCurrency($id: ID!) {
    getCurrency(id: $id) {
      label
      value
      symbol
      id
      createdAt
      updatedAt
    }
  }
`;
export const listCurrencies = /* GraphQL */ `
  query ListCurrencies(
    $filter: ModelCurrencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurrencies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        label
        value
        symbol
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIndustry = /* GraphQL */ `
  query GetIndustry($id: ID!) {
    getIndustry(id: $id) {
      label
      value
      id
      createdAt
      updatedAt
    }
  }
`;
export const listIndustries = /* GraphQL */ `
  query ListIndustries(
    $filter: ModelIndustryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIndustries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        label
        value
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      label
      value
      id
      createdAt
      updatedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        label
        value
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStore = /* GraphQL */ `
  query GetStore($id: ID!) {
    getStore(id: $id) {
      id
      storeName
      storeURL
      salesGoal
      budgetGoal
      currency {
        label
        value
        symbol
        id
        createdAt
        updatedAt
      }
      industry {
        label
        value
        id
        createdAt
        updatedAt
      }
      role {
        label
        value
        id
        createdAt
        updatedAt
      }
      klaviyoAPIKey
      tiktokID
      googleAnalyticsAccount {
        id
        name
        created
      }
      googleAnalyticsProperty {
        id
        name
        created
        websiteUrl
      }
      googleAnalyticsView {
        id
        name
        websiteUrl
        created
        eCommerceTracking
      }
      googleAdsAccount {
        currencyCode
        customerId
        customerName
        descriptiveName
        id
      }
      facebookAdsAccount {
        name
        id
      }
      googleSearchAccount {
        siteUrl
        permissionLevel
      }
      isShopifyConnected
      isMonthlyNotificationsEnabled
      isWeeklyNotificationsEnabled
      isDailyNotificationsEnabled
      createdAt
      updatedAt
      storeCurrencyId
      storeIndustryId
      storeRoleId
      owner
    }
  }
`;
export const listStores = /* GraphQL */ `
  query ListStores(
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        storeName
        storeURL
        salesGoal
        budgetGoal
        currency {
          label
          value
          symbol
          id
          createdAt
          updatedAt
        }
        industry {
          label
          value
          id
          createdAt
          updatedAt
        }
        role {
          label
          value
          id
          createdAt
          updatedAt
        }
        klaviyoAPIKey
        tiktokID
        googleAnalyticsAccount {
          id
          name
          created
        }
        googleAnalyticsProperty {
          id
          name
          created
          websiteUrl
        }
        googleAnalyticsView {
          id
          name
          websiteUrl
          created
          eCommerceTracking
        }
        googleAdsAccount {
          currencyCode
          customerId
          customerName
          descriptiveName
          id
        }
        facebookAdsAccount {
          name
          id
        }
        googleSearchAccount {
          siteUrl
          permissionLevel
        }
        isShopifyConnected
        isMonthlyNotificationsEnabled
        isWeeklyNotificationsEnabled
        isDailyNotificationsEnabled
        createdAt
        updatedAt
        storeCurrencyId
        storeIndustryId
        storeRoleId
        owner
      }
      nextToken
    }
  }
`;
