import { AnResponseData } from "../../pages/api/markets-feature-ad-spend";
import { IExtendedFilter } from "../hooks/useFiltering";

const ENDPOINTS: any = {
  ordersData: ({
    storeId,
    from,
    to,
    currency,
    page,
    limit,
    sorting,
    search,
    filter,
    groupBy,
  }: {
    storeId: string;
    from: string;
    to: string;
    currency: string;
    page: string | number;
    limit: string | number;
    sorting: { attribute: string; property: string };
    search: string;
    filter: IExtendedFilter[];
    groupBy: string;
  }) => {
    const encodedFilter = encodeURIComponent(JSON.stringify(filter));
    return `/api/store/orders?storeId=${storeId}&startDate=${from}&endDate=${to}&currency=${currency}&page=${page}&limit=${limit}&attrib=${sorting?.attribute}&property=${sorting?.property}&searchById=${search}&filter=${encodedFilter}&groupBy=${groupBy}`;
  },
  exportOrders: ({
    storeId,
    from,
    to,
    filter,
    marketsAdSpend,
  }: {
    storeId: string;
    from: string;
    to: string;
    filter: IExtendedFilter[];
    marketsAdSpend: AnResponseData;
  }) => {
    const encodedFilter = encodeURIComponent(JSON.stringify(filter));
    const encodedMarketSpend = encodeURIComponent(JSON.stringify(marketsAdSpend));

    return `/api/store/orders/export?storeId=${storeId}&startDate=${from}&endDate=${to}&filter=${encodedFilter}&marketsAdSpend=${encodedMarketSpend}`;
  },
  summary: ({
    storeId,
    from,
    to,
    currency,
    search,
    filter,
    customerId,
    accountId,
    managerCustomerId,
    tiktokId,
    prevStartDate,
    prevEndDate,
    targetCurrency,
    baseCurrency,
  }: {
    storeId: string;
    from: string;
    to: string;
    currency: string;
    search: string;
    filter: IExtendedFilter[];
    customerId: string;
    accountId: string;
    managerCustomerId: string;
    tiktokId: string;
    prevStartDate?: string;
    prevEndDate?: string;
    baseCurrency?: string;
    targetCurrency?: string;
  }) => {
    const encodedFilter = encodeURIComponent(JSON.stringify(filter));
    return `/api/store/orders/summary?storeId=${storeId}&startDate=${from}&endDate=${to}&currency=${currency}&searchById=${search}&filter=${encodedFilter}&customerId=${customerId}&managerCustomerId=${managerCustomerId}&tiktokId=${tiktokId}
    &baseCurrency=${baseCurrency}&targetCurrency=${targetCurrency}${
      prevStartDate && prevEndDate
        ? `&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`
        : ""
    }${accountId ? `&accountId=${accountId}` : ""}`;
  },
  marketsAdsSpend: ({
    customerId,
    accountId,
    managerCustomerId,
    tiktokId,
    from,
    to,
    prevStartDate,
    prevEndDate,
    storeId,
    targetCurrency,
    baseCurrency,
  }: {
    storeId: string;
    customerId: string;
    accountId: string;
    managerCustomerId: string;
    tiktokId: string;
    from: string;
    to: string;
    prevStartDate?: string;
    prevEndDate?: string;
    baseCurrency?: string;
    targetCurrency?: string;
  }) =>
    `/api/markets-feature-ad-spend?storeId=${storeId}&customerId=${customerId}&managerCustomerId=${managerCustomerId}&tiktokId=${tiktokId}
      &startDate=${from}&endDate=${to}&baseCurrency=${baseCurrency}&targetCurrency=${targetCurrency}${
      prevStartDate && prevEndDate
        ? `&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`
        : ""
    }${accountId ? `&accountId=${accountId}` : ""}`,
  splitByTopCountryRegionAdsSpend: ({
    customerId,
    accountId,
    managerCustomerId,
    tiktokId,
    from,
    to,
    prevStartDate,
    prevEndDate,
    storeId,
    targetCurrency,
    baseCurrency,
  }: {
    storeId: string;
    customerId: string;
    accountId: string;
    managerCustomerId: string;
    tiktokId: string;
    from: string;
    to: string;
    prevStartDate?: string;
    prevEndDate?: string;
    baseCurrency?: string;
    targetCurrency?: string;
  }) =>
    `/api/markets-feature-ad-spend?storeId=${storeId}&customerId=${customerId}&managerCustomerId=${managerCustomerId}&tiktokId=${tiktokId}
        &startDate=${from}&endDate=${to}&baseCurrency=${baseCurrency}&targetCurrency=${targetCurrency}${
      prevStartDate && prevEndDate
        ? `&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`
        : ""
    }${accountId ? `&accountId=${accountId}` : ""}`,
};

export { ENDPOINTS };
