import React, { useState, useMemo } from 'react';

const SidebarCollapseContext = React.createContext({
  sidebarCollapse: false,
  setSidebarCollapse: (isCollapsed: boolean) => {},
});

function SidebarCollapseProvider(props: any) {
  const [sidebarCollapse, setSidebarCollapse] = useState(false);
  const value = useMemo(
    () => ({
      sidebarCollapse,
      setSidebarCollapse,
    }),
    [sidebarCollapse]
  );
  return <SidebarCollapseContext.Provider value={value} {...props} />;
}

function useSidebarCollapse() {
  const context = React.useContext(SidebarCollapseContext);
  if (context === undefined) {
    throw new Error('useSidebarCollapse must be used within an WindowSizeProvider');
  }
  return context;
}

export { SidebarCollapseProvider, useSidebarCollapse, SidebarCollapseContext };
