export enum UserPermissions {
  SETTINGS_CONNECTIONS = "SETTINGS_CONNECTIONS",
  SETTINGS_TEAM = "SETTINGS_TEAM",
  ADS_OVERVIEW = "ADS_OVERVIEW",
  STORE_OVERVIEW = "STORE_OVERVIEW",
  PRODUCTS = "PRODUCTS",
  ORDERS = "ORDERS",
  FINANCE = "FINANCE",
  CREATIVE_OVERVIEW = "CREATIVE_OVERVIEW",
  META_ADS = "META_ADS",
  GOOGLE_ADS = "GOOGLE_ADS",
  TIKTOK_ADS = "TIKTOK_ADS",
  KLAVIYO = "KLAVIYO",
  RECHARGE = "RECHARGE",
  GA4 = "GA4",
  GOOGLE_ANALYTICS = "GOOGLE_ANALYTICS",
  SEO = "SEO",
  SETTINGS_COSTS_PRODUCTS = "SETTINGS_COSTS_PRODUCTS",
  SETTINGS_COSTS_STAFF_COSTS = "SETTINGS_COSTS_STAFF_COSTS",
  SETTINGS_COSTS_SHIPPING = "SETTINGS_COSTS_SHIPPING",
  SETTINGS_COSTS_FULFILLMENT = "SETTINGS_COSTS_FULFILLMENT",
  SETTINGS_COSTS_MARKETS = "SETTINGS_COSTS_MARKETS",
  SETTINGS_COSTS_TRANSACTION_FEES = "SETTINGS_COSTS_TRANSACTION_FEES",
  SETTINGS_COSTS_MARKETING_COSTS = "SETTINGS_COSTS_MARKETING_COSTS",
  SETTINGS_COSTS_OTHER_COSTS = "SETTINGS_COSTS_OTHER_COSTS",
  SETTINGS_COSTS_DEFAULTS = "SETTINGS_COSTS_DEFAULTS",
}

export enum UserRole {
  ADMIN = "Admin",
  TEAM_MEMBER = "Team Member",
}

export type Invite = {
  storeId: string;
  email: string;
  permissions: UserPermissions[];
};

export type UserRolePermissionsItem = {
  userId: string;
  storeId: string;
  permissions: UserPermissions[];
  role: UserRole;
  name: string;
  email: string;
  agencyId?: string;
};

export type TeamMember = {
  name: string;
  email: string;
  status: "Invited" | "Active";
  role: UserRole;
  permissionsType: "Full" | "Partial";
  isInvite: boolean;
  userId?: string;
  permissions: UserPermissions[];
};

export type Agency = {
  agencyId: string;
  agencyName: string;
  storePermissions: { [storeId: string]: UserPermissions[] };
  storeNames: { [storeId: string]: string }; //store id: store name
};

export type AgencyMemberItem = {
  agencyId: string;
  userId: string;
  stores: { [storeId: string]: string }; //store id: store name
  role: UserRole;
  name: string;
  email: string;
};

export type AgencyInvite = {
  agencyId: string;
  email: string;
  stores: { [storeId: string]: string }; //store id: store name
};

export type AgencyTeamMember = Omit<TeamMember, "permissions"> & {
  stores: { [storeId: string]: string }; //store id: store name
};
