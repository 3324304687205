import { Card, Chart, GoogleError, Period, PieChart, PinnedCard } from "../../../types";
import { CustomError } from "../../errorTypes";

type PropertySummary = {
  property: string;
  displayName: string;
  propertyType: string;
  parent: string;
};

type AccountSummary = {
  name: string;
  account: string;
  displayName: string;
  propertySummaries?: PropertySummary[];
};

export type APIGA4AccountSummary = {
  accountSummaries: AccountSummary[];
  error?: GoogleError;
  nextPageToken?: string;
};

export type GA4AccountProperty = {
  account: {
    accountId: string;
    accountName: string;
  };
  properties: { propertyId: string; propertyName: string; accountId: string }[];
};

export type GA4RequestParams = Period & {
  propertyId: string;
  token: string;
};

export type APIGA4RequestParams = GA4RequestParams & {
  metrics: string[];
  dimensions?: string[];
};

export type Row = {
  dimensionValues: { value: string }[];
  metricValues: { value: string }[];
};

export type APIGA4ReportResponse = {
  dimensionHeaders: { name: string }[];
  metricHeaders: { name: string; type: string }[];
  rows?: Row[];
  totals: (Row | {})[];
  metadata: {
    schemaRestrictionResponse: {};
    currencyCode: string;
    timeZone: string;
  };
  error?: GoogleError;
};

export enum GA4CardsKeys {
  sessions,
  conversionRate,
  bounceRate,
  addToCartRate,
  purchases,
  users,
  pageViews,
  averageOrderValue,
  purchaseRevenue,
  checkoutRate,
}

export type GA4Cards = {
  [key in keyof typeof GA4CardsKeys]: Card | PinnedCard<GA4CardsKeys>;
};

export const chartKeys = ["conversionRate", "visitors", "orders"] as const;
export const chartMetricKeys = ["conversionRate", "sessions", "purchases"] as const;

export type GA4Charts = {
  [key in (typeof chartKeys)[number]]: Chart;
};

export type GA4CardsResponse =
  | {
      cards: GA4Cards;
      charts: GA4Charts;
    }
  | { customError: CustomError }
  | null;

export const funnelKeys = [
  "sessions",
  "productViews",
  "addsToCart",
  "checkouts",
  "transactions",
] as const;

export type GA4Funnel = {
  [key in (typeof funnelKeys)[number]]: {
    [key: string]: number;
    total: number;
  };
};

export type GA4FunnelResponse = GA4Funnel | { customError: CustomError } | null;

export type GA4UsersBySourceResponse = PieChart | { customError: CustomError } | null;

const transactionsByProductKeys = [
  "name",
  "productPageviews",
  "addsToCartRate",
  "revenuePerPurchase",
  "purchases",
  "revenue",
] as const;

export type GA4TransactionsByProductRow = {
  [key in (typeof transactionsByProductKeys)[number]]: number | string;
};

export type GrowthDetails = {
  growth: string | number;
  is_growing: boolean;
};

export type MetricWithGrowth = {
  value: string | number;
  growth: string | number;
  prevPeriod: string | number;
};

export type GA4TransactionsByProductRowWithComparison = {
  name: string;
  productPageviews: MetricWithGrowth;
  productPageviewsGrowth: GrowthDetails;
  addsToCartRate: MetricWithGrowth;
  addsToCartRateGrowth: GrowthDetails;
  revenuePerPurchase: MetricWithGrowth;
  revenuePerPurchaseGrowth: GrowthDetails;
  purchases: MetricWithGrowth;
  purchasesGrowth: GrowthDetails;
  revenue: MetricWithGrowth;
  revenueGrowth: GrowthDetails;
};

export type GA4TransactionsByProductResponse =
  | GA4TransactionsByProductRowWithComparison[]
  | { customError: CustomError }
  | null;

const transactionsBySourceKeys = [
  "source",
  "sessions",
  "productPageviews",
  "addsToCartRate",
  "conversionRate",
  "purchases",
  "value",
] as const;

export type GA4TransactionsBySourceRow = {
  [key in (typeof transactionsBySourceKeys)[number]]: number | string;
};

export type GA4TransactionsBySourceRowWithComparison = {
  source: string;
  productPageviews: MetricWithGrowth;
  sessions: MetricWithGrowth;
  sessionsGrowth: GrowthDetails;
  productPageviewsGrowth: GrowthDetails;
  addsToCartRate: MetricWithGrowth;
  addsToCartRateGrowth: GrowthDetails;
  conversionRate: MetricWithGrowth;
  conversionRateGrowth: GrowthDetails;
  purchases: MetricWithGrowth;
  purchasesGrowth: GrowthDetails;
  revenue: MetricWithGrowth;
  revenueGrowth: GrowthDetails;
};

export type GA4TransactionsBySourceResponse =
  | GA4TransactionsBySourceRowWithComparison[]
  | { customError: CustomError }
  | null;

export type MetricValue = {
  date: string;
  value: number;
};
