type ServerErrorsInfo = Record<
  ServerErrorCodes,
  {
    title: string;
    reconnectButtons?: boolean;
  }
>;

export enum ServerErrorCodes {
  "PRODUCT_NOT_READY_ERROR" = "PRODUCT_NOT_READY_ERROR",
  "UNAUTHORISED" = "UNAUTHORISED",
  "INVALID_API_KEY" = "INVALID_API_KEY",
  "NO_PERMISSIONS" = "NO_PERMISSIONS",
  "ACCESS_TOKEN_EXPIRED" = "ACCESS_TOKEN_EXPIRED",
  "NO_TOKEN" = "NO_TOKEN",
  "API_ERROR" = "API_ERROR",
  "RATE_LIMIT" = "RATE_LIMIT",
}

export const serverErrorsInfo: ServerErrorsInfo = {
  [ServerErrorCodes.PRODUCT_NOT_READY_ERROR]: {
    title: "We're currently syncing your Store data. Check back soon.",
  },
  [ServerErrorCodes.UNAUTHORISED]: {
    title: "There was an error connecting the service. Try reconnecting the required services:",
    reconnectButtons: true,
  },
  [ServerErrorCodes.INVALID_API_KEY]: {
    title: "There was an error with API key. Try reconnecting the required services:",
    reconnectButtons: true,
  },
  [ServerErrorCodes.NO_PERMISSIONS]: {
    title:
      "User does not have sufficient permissions for this profile. Try reconnecting the required services:",
    reconnectButtons: true,
  },
  [ServerErrorCodes.ACCESS_TOKEN_EXPIRED]: {
    title: "Your account access token has expired. Please reconnect to regain access.",
    reconnectButtons: true,
  },
  [ServerErrorCodes.NO_TOKEN]: {
    title:
      "Your account is not connected to the required services. Try reconnecting the required services:",
    reconnectButtons: true,
  },
  [ServerErrorCodes.API_ERROR]: {
    title:
      "Something went wrong on the API side. We apologize for the inconvenience. Please try again later or contact support for assistance.",
  },
  [ServerErrorCodes.RATE_LIMIT]: {
    title:
      "Rate limit exceeded for requests for this service. Please wait until the rate limit is reset to make further requests.",
  },
};
