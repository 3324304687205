import React from 'react';

const WidthContext = React.createContext({
  isMobile: false,
  isLaptop: false,
  isTablet: false,
});

function WidthProvider(props: any) {
  const [size, setSize] = React.useState({
    isMobile: false,
    isLaptop: false,
    isTablet: false,
  });

  const value = React.useMemo(
    () => ({
      isMobile: size.isMobile,
      isTablet: size.isTablet,
      isLaptop: size.isLaptop,
    }),
    [size.isTablet, size.isMobile, size.isLaptop]
  );

  React.useEffect(() => {
    if (typeof window === 'undefined') return undefined;
    function handleResize() {
      setSize({
        isMobile: window.innerWidth < 700,
        isLaptop: window.innerWidth < 1600,
        isTablet: window.innerWidth < 1200,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <WidthContext.Provider value={value} {...props} />;
}

function useWindowSize() {
  const context = React.useContext(WidthContext);
  if (context === undefined) {
    throw new Error('useWindowSize must be used within an WindowSizeProvider');
  }
  return context;
}

export { WidthProvider, useWindowSize, WidthContext };
