import { resolver } from "./index";
import { FinancesCategory } from "../../types";

interface QueryParams {
  googleSearchSite?: string;
  googleAdsCustomerID?: string;
  facebookAdsAccountID?: string;
  googleAnalyticsViewID?: string;
  managerCustomerId?: string;
  from: string;
  to: string;
  prevStartDate?: string;
  prevEndDate?: string;
  storeId: string;
  propertyId?: string;
  baseCurrency?: string;
  targetCurrency?: string;
  salesGoalMetric?: string;
}

const ENDPOINTS: any = {
  cards: {
    dataForPeriod: ({
      googleSearchSite,
      googleAdsCustomerID,
      facebookAdsAccountID,
      googleAnalyticsViewID,
      managerCustomerId,
      propertyId,
      from,
      to,
      prevStartDate,
      prevEndDate,
      storeId,
      baseCurrency,
      targetCurrency,
    }: QueryParams) =>
      `/api/dashboard/cards?url=${googleSearchSite}&managerCustomerId=${managerCustomerId}&customerId=${googleAdsCustomerID}&accountId=${facebookAdsAccountID}&viewId=${googleAnalyticsViewID}&storeId=${storeId}&startDate=${from}&endDate=${to}&baseCurrency=${baseCurrency}&targetCurrency=${targetCurrency}${
        prevStartDate && prevEndDate
          ? `&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`
          : ""
      }${propertyId ? `&propertyId=${propertyId}` : ""}`,
  },
  goals: {
    dataForPeriod: ({
      googleAdsCustomerID,
      facebookAdsAccountID,
      googleAnalyticsViewID,
      managerCustomerId,
      from,
      to,
      prevStartDate,
      prevEndDate,
      storeId,
      propertyId,
      baseCurrency,
      targetCurrency,
      salesGoalMetric,
    }: QueryParams) =>
      `/api/dashboard/goals?managerCustomerId=${managerCustomerId}&customerId=${googleAdsCustomerID}&accountId=${facebookAdsAccountID}&viewId=${googleAnalyticsViewID}&storeId=${storeId}&startDate=${from}&endDate=${to}&salesGoalMetric=${salesGoalMetric}&baseCurrency=${baseCurrency}&targetCurrency=${targetCurrency}${
        prevStartDate && prevEndDate
          ? `&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`
          : ""
      }${propertyId ? `&propertyId=${propertyId}` : ""}`,
  },
};

async function setGoal({ goal, category }: { goal: string; category: FinancesCategory }) {
  return resolver(ENDPOINTS.goal(goal, category), { method: "POST" });
}

export { ENDPOINTS, setGoal };
