const ENDPOINTS = {
  overview: ({
    storeId = "",
    googleAdsCustomerID = "",
    facebookAdsAccountID = "",
    managerCustomerId = "",
    from,
    to,
    prevStartDate,
    prevEndDate,
    filterGG,
    filterFB,
  }: {
    storeId?: string;
    googleAdsCustomerID?: string;
    facebookAdsAccountID?: string;
    managerCustomerId?: string;
    from: string;
    to: string;
    prevStartDate: string;
    prevEndDate: string;
    filterGG?: string;
    filterFB?: string;
  }) =>
    `/api/ads-overview?storeId=${storeId}&customerId=${googleAdsCustomerID}&managerCustomerId=${managerCustomerId}&accountId=${facebookAdsAccountID}&startDate=${from}&endDate=${to}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}&filterGG=${filterGG}&filterFB=${filterFB}`,
};

export { ENDPOINTS };
