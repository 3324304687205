const ENDPOINTS = {
  dataForPeriod: ({
    id,
    storeId,
    from,
    to,
    prevStartDate,
    prevEndDate,
  }: {
    id: string;
    from: string;
    to: string;
    prevStartDate: string;
    prevEndDate: string;
    storeId: string;
  }) =>
    `/api/analytics?viewId=${id}&storeId=${storeId}&startDate=${from}&endDate=${to}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`,
};

export { ENDPOINTS };
