import { GroupByOption } from "../../context/DateDropdownContext";

const ENDPOINTS = {
  cardsDataForPeriod: ({
    from,
    to,
    prevStartDate,
    prevEndDate,
    storeId,
    groupByValue,
  }: {
    from: string;
    to: string;
    prevStartDate: string;
    prevEndDate: string;
    storeId: string | undefined;
    groupByValue: GroupByOption;
  }) =>
    `/api/recharge/cards?storeId=${storeId}&startDate=${from}&endDate=${to}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}&groupBy=${groupByValue}`,
};

export { ENDPOINTS };
