import {
  endOfMonth,
  endOfYear,
  isEqual,
  isSameDay,
  isSameMonth,
  isSameYear,
  startOfDay,
  startOfMonth,
  startOfYear,
} from "date-fns";

export function isFullMonth(startDate: Date, endDate: Date): boolean {
  const startOfStartMonth = startOfMonth(startDate);
  const endOfEndMonth = startOfDay(endOfMonth(endDate));

  return (
    isEqual(startOfStartMonth, startDate) &&
    isEqual(endOfEndMonth, endDate) &&
    isSameMonth(startDate, endDate)
  );
}

export function isFullYear(startDate: Date, endDate: Date): boolean {
  const startOfStartYear = startOfYear(startDate);
  const endOfEndYear = startOfDay(endOfYear(endDate));
  return (
    isEqual(startOfStartYear, startDate) &&
    isEqual(endOfEndYear, endDate) &&
    isSameYear(startDate, endDate)
  );
}

export function isMonthToDate(startDate: Date, endDate: Date): boolean {
  const startOfMonthDate = startOfMonth(startDate);
  return isSameDay(startDate, startOfMonthDate) && !isEqual(startDate, startOfYear(startDate));
}

export function isYearToDate(startDate: Date, endDate: Date): boolean {
  const startOfMonthDate = startOfYear(startDate);
  return isSameDay(startDate, startOfMonthDate);
}
