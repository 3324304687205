import { queryFetcher } from "./index";

const ENDPOINTS = {
  signup: () => "/api/signup",
  confirm: () => "/api/signup/confirm",
  processAcceptedInvite: () => "/api/store/settings/team/process-accepted-invite",
  processAgencyAcceptedInvite: () =>
    "/api/store/settings/team/agency/agency-process-accepted-invite",
};

function signUp<T extends object>(payload: T) {
  return queryFetcher(ENDPOINTS.signup(), {
    method: "POST",
    body: JSON.stringify(payload),
  });
}

function confirmSignUp<T extends object>(payload: T) {
  return queryFetcher(ENDPOINTS.confirm(), {
    method: "POST",
    body: JSON.stringify(payload),
  });
}

export { ENDPOINTS, signUp, confirmSignUp };
