import { useEffect, useState } from "react";
import { useStoresContext } from "../../context/StoresContext";
import { partnerDomainAgencyMap } from "../../lib/utils/agency";

export default function useIsSwapAgency() {
  const { selectedStore } = useStoresContext();
  const [isSwapAgency, setIsSwapAgency] = useState(false);
  useEffect(() => {
    const url = window.location.hostname;
    if (!url) return;
    setIsSwapAgency(
      partnerDomainAgencyMap[url]?.partnerId === "0fb237cf-4c27-4157-bb4d-6ababdf6eff7",
    );
  }, []);

  return isSwapAgency || selectedStore?.linkedPartnerId === "0fb237cf-4c27-4157-bb4d-6ababdf6eff7";
}
