import { kv } from "@vercel/kv";
import { setCacheWithExpiry } from "../../utils";

const EXCHANGE_RATES_BASE_URL = "https://api.exchangeratesapi.io/v1";

type ExchangeRateAPIProps = {
  startDate: string;
  endDate: string;
  baseCurrency: string;
  targetCurrency: string;
};

const { EXCHANGE_RATES_API_KEY } = process.env;

export async function getExchageRatesByCurrencyAndDateRange({
  startDate,
  endDate,
  baseCurrency,
  targetCurrency,
}: ExchangeRateAPIProps) {
  const cacheKey = `exchange-rates-api-metas-ads-${startDate}-${endDate}-${baseCurrency}-${targetCurrency}`;
  const cachedData = await kv.get(cacheKey);

  if (cachedData) {
    return cachedData;
  }

  const resp = await fetch(
    `${EXCHANGE_RATES_BASE_URL}/timeseries?access_key=${EXCHANGE_RATES_API_KEY}&start_date=${startDate}&end_date=${endDate}&base=${baseCurrency}&symbols=${targetCurrency}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  const data = await resp.json();

  if (data?.error) {
    return data?.error;
  }

  await setCacheWithExpiry({
    cacheKey,
    data: data?.rates,
    query: cacheKey,
    expiryTimeInSeconds: 60 * 30,
  });

  return data?.rates || null;
}
