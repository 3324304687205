import CircularProgress from "@mui/material/CircularProgress";
import styled from "@mui/system/styled";
import { useWhitelabeling } from "../../hooks/useWhitelabeling";

const Wrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "fullPage",
})(({ fullPage }: { fullPage: boolean }) => ({
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  padding: "100px 0",
  flexDirection: "column",
  alignItems: "center",
  flexGrow: 1,

  ...(() => {
    if (fullPage) {
      return {
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      };
    }
  })(),
  "@media (max-width: 750px)": {
    width: "300px",
    span: {
      width: "50px !important",
      height: "50px !important",
    },
  },
}));

const Text = styled("div")(({ theme }) => ({
  fontWeight: 500,
  fontSize: "32px",
  padding: "60px 0 20px 0",
  whiteSpace: "pre-line",
  color: "#253858",

  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
    lineHeight: "28px",
    padding: "30px 0 10px 0",
    whiteSpace: "normal",
  },
}));

interface LoadingOverlayProps {
  fullPage?: boolean;
}

export function LoadingOverlay({ fullPage = false }: LoadingOverlayProps) {
  const {
    data: { partnerId },
  } = useWhitelabeling();

  return (
    <Wrapper fullPage={fullPage}>
      <CircularProgress style={{ color: partnerId ? "#aaa" : "#5664D2", width: 90, height: 90 }} />
      <Text>We&apos;re gathering today’s {"\n"} sales and marketing data</Text>
    </Wrapper>
  );
}

export function NeutralLoadingOverlay({ fullPage = false }: LoadingOverlayProps) {
  return (
    <Wrapper fullPage={fullPage}>
      <CircularProgress style={{ color: "#aaa", width: 90, height: 90 }} />
    </Wrapper>
  );
}
