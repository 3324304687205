import { useAuthContext } from "../../context/AmplifyContext";

export const useIntercom = () => {
  const { userAttributes } = useAuthContext();
  const email = userAttributes?.email;

  const trackIntercomEvent = (event: string, eventName: any) => {
    const intercomInstance = typeof window !== "undefined" && window?.Intercom;
    if (intercomInstance) {
      console.log("<Sending IntercomEvent>", event, eventName);
      intercomInstance(event, eventName, { email });
    }
  };
  return {
    trackIntercomEvent,
  };
};
