import { DynamoDBDocument } from "@aws-sdk/lib-dynamodb";
import { DynamoDB } from "@aws-sdk/client-dynamodb";

const db = DynamoDBDocument.from(
  new DynamoDB({
    apiVersion: "latest",
    region: "eu-west-1",
    maxAttempts: 5,
    requestHandler: {
      httpsAgent: {
        requestTimeout: 5000,
        maxSockets: 1000,
      },
    },
    credentials: {
      accessKeyId: process.env.AWS_DYNAMO_DB_ACCESS_KEY_ID as string,
      secretAccessKey: process.env.AWS_DYNAMO_DB_SECRET_ACCESS_KEY as string,
    },
  }),
);

export default db;
