const ENDPOINTS: any = {
  attribution: {
    dataForPeriod: ({
      customerId,
      accountId,
      managerCustomerId,
      tiktokId,
      from,
      to,
      prevStartDate,
      prevEndDate,
      storeId,
    }: {
      storeId: string;
      customerId: string;
      accountId: string;
      managerCustomerId: string;
      tiktokId: string;
      from: string;
      to: string;
      prevStartDate?: string;
      prevEndDate?: string;
    }) =>
      `/api/ads-attribution/table-total?storeId=${storeId}&customerId=${customerId}&managerCustomerId=${managerCustomerId}&tiktokId=${tiktokId}
      &startDate=${from}&endDate=${to}${
        prevStartDate && prevEndDate
          ? `&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`
          : ""
      }${accountId ? `&accountId=${accountId}` : ""}`,
  },
  attribution_facebook: {
    tableFacebook: ({
      accountId,
      storeId,
      from,
      to,
      prevStartDate,
      prevEndDate,
    }: {
      accountId: string;
      storeId: string;
      from: string;
      to: string;
      prevStartDate?: string;
      prevEndDate?: string;
    }) =>
      `/api/ads-attribution/table-facebook?storeId=${storeId}&accountId=${accountId}&startDate=${from}&endDate=${to}${
        prevStartDate && prevEndDate
          ? `&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`
          : ""
      }`,
  },
  attribution_google: {
    tableGoogle: ({
      customerId,
      managerCustomerId,
      from,
      to,
      prevStartDate,
      prevEndDate,
      storeId,
    }: {
      storeId: string;
      customerId: string;
      managerCustomerId: string;
      from: string;
      to: string;
      prevStartDate?: string;
      prevEndDate?: string;
    }) =>
      `/api/ads-attribution/table-google?storeId=${storeId}&customerId=${customerId}&managerCustomerId=${managerCustomerId}
      &startDate=${from}&endDate=${to}${
        prevStartDate && prevEndDate
          ? `&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`
          : ""
      }`,
  },
  performance_table_google: {
    performanceTableGoogle: ({
      customerId,
      managerCustomerId,
      from,
      to,
      prevStartDate,
      prevEndDate,
      storeId,
      pageSize,
      campaigns,
      adsets,
      keywords,
      products,
      pageToken,
      search,
      orderBy,
      sortPropertyName,
    }: {
      storeId: string;
      customerId: string;
      managerCustomerId: string;
      from: string;
      to: string;
      prevStartDate?: string;
      prevEndDate?: string;
      pageSize?: number;
      campaigns?: any;
      adsets?: any;
      keywords?: any;
      products?: any;
      pageToken?: any;
      search?: any;
      orderBy?: string;
      sortPropertyName?: string;
    }) =>
      `/api/ads-attribution/table-performance-google?storeId=${storeId}&search=${search}&orderBy=${orderBy}&sortPropertyName=${sortPropertyName}&customerId=${customerId}&managerCustomerId=${managerCustomerId}&pageSize=${pageSize}&pageToken=${pageToken}&campaigns=${campaigns}&adsets=${adsets}&keywords=${keywords}&products=${products}&startDate=${from}&endDate=${to}${
        prevStartDate && prevEndDate
          ? `&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`
          : ""
      }`,
  },
  attribution_cardsAndGraph: {
    tableCardsAndGraph: ({
      customerId,
      accountId,
      managerCustomerId,
      tiktokId,
      from,
      to,
      prevStartDate,
      prevEndDate,
      storeId,
    }: {
      storeId: string;
      customerId: string;
      accountId: string;
      managerCustomerId: string;
      tiktokId: string;
      from: string;
      to: string;
      prevStartDate?: string;
      prevEndDate?: string;
    }) =>
      `/api/ads-attribution/cards?storeId=${storeId}&customerId=${customerId}&managerCustomerId=${managerCustomerId}&tiktokId=${tiktokId}
      &startDate=${from}&endDate=${to}${
        prevStartDate && prevEndDate
          ? `&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`
          : ""
      }${accountId ? `&accountId=${accountId}` : ""}`,
  },
};

export { ENDPOINTS };
