import { queryFetcher } from './index';

const ENDPOINTS = {
  save: () => '/api/token/save',
};

function saveToken(props: {
  accessToken?: string;
  accessTokenExpires?: string;
  refreshToken?: string;
  provider?: string;
  userID?: string;
  storeId?: string;
}) {
  return queryFetcher(ENDPOINTS.save(), {
    method: 'POST',
    body: JSON.stringify(props),
  });
}

export { ENDPOINTS, saveToken };
