import { queryFetcher } from "./index";

const ENDPOINTS = {
  data: ({
    advertiserId,
    from,
    to,
    prevStartDate,
    prevEndDate,
    storeId,
  }: {
    advertiserId: number;
    from: string;
    to: string;
    storeId?: string;
    prevStartDate: string;
    prevEndDate: string;
  }) =>
    `/api/tiktok?storeId=${storeId}&advertiserId=${advertiserId}&startDate=${from}&endDate=${to}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`,
};

function getData<T>(props: {
  advertiserId: number;
  from: string;
  to: string;
  storeId?: string;
  prevStartDate: string;
  prevEndDate: string;
}) {
  return queryFetcher<T>(ENDPOINTS.data(props));
}

export { ENDPOINTS, getData };
