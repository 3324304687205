import { getConnectedStores } from ".";
import { FormTypes } from "../../src/pages/Onboarding/types/types";
import type { Store } from "../../types";
import { getFacebookAccessToken } from "../auth/auth-db";
import db from "../aws/dynamodb";
import { DEV_STORE_TABLE_NAME, STORE_TABLE_NAME } from "../constants";
import { ErrorType } from "../errorTypes";
import { chunkArray } from "../utils";
import { isDev } from "../utils/environment";
import { getCurrency, getIndustry } from "./store";
import { getUserRolePermissions } from "./user-role-permissions-table";

const TABLE_NAME = isDev ? DEV_STORE_TABLE_NAME : STORE_TABLE_NAME;

async function getStoreItem(storeId: string, attributesToGet?: string[]) {
  try {
    const { Item } = await db.get({
      TableName: TABLE_NAME,
      Key: {
        id: storeId,
      },
      ...(attributesToGet && { ProjectionExpression: attributesToGet.join(",") }),
    });

    return Item;
  } catch (e) {
    console.error("ERROR GETTING STORE ITEM", e);
    throw new Error("Error getting store item");
  }
}

export async function getFBAccountId(storeId: string | undefined) {
  if (!storeId) return null;

  const store = await getStoreItem(storeId);

  if (!store) {
    return null;
  }

  return (store as Store).facebookAdsAccount?.id;
}

export async function getGoogleAdsCustomerIdCustomerManagerId(storeId: string) {
  const store = await getStoreItem(storeId);

  if (!store) {
    return null;
  }

  return {
    customerManagerId: (store as Store).googleAdsAccount?.customerId,
    customerId: (store as Store).googleAdsAccount?.id,
  };
}

export async function getGA4PropertyId(storeId: string) {
  const store = await getStoreItem(storeId);

  if (!store) {
    return null;
  }

  return (store as Store).GA4Property?.id;
}

export async function getURL(storeId: string) {
  const store = await getStoreItem(storeId);

  if (!store) {
    return null;
  }

  return (store as Store).googleSearchAccount?.siteUrl;
}

export async function getStoresByUserId(userId: string) {
  try {
    const { Items } = await db.scan({
      TableName: TABLE_NAME,
      FilterExpression: "contains(#owner, :owner)",
      ExpressionAttributeNames: {
        "#owner": "owner",
      },
      ExpressionAttributeValues: {
        ":owner": userId,
      },
    });

    if (!Items || Items.length === 0) {
      return null;
    }

    return Items.map((store) => ({
      id: store.id,
      storeName: store.storeName,
    }));
  } catch (e) {
    console.log("ERROR", e);
    return null;
  }
}

export async function listStoresByUserId(userId: string | undefined | null) {
  if (!userId) {
    return null;
  }
  try {
    const { Items } = await db.scan({
      TableName: TABLE_NAME,
      FilterExpression: "contains(#owner, :owner)",
      ExpressionAttributeNames: {
        "#owner": "owner",
      },
      ExpressionAttributeValues: {
        ":owner": userId,
      },
    });
    if (Items && Items.length > 0) {
      const promises = Items.map(async (store) => {
        const isFbTokenExpired = store.facebookAdsAccount
          ? await checkFacebookToken(userId, store.id)
          : false;
        const [currency, industry, connectedStores, rolePermissions] = await Promise.all([
          getCurrency(store.storeCurrencyId),
          getIndustry(store.storeIndustryId),
          getConnectedStores(store.id),
          getUserRolePermissions({ storeId: store.id, userId }),
        ]);
        return {
          ...store,
          currency,
          industry,
          role: rolePermissions?.role,
          permissions: rolePermissions?.permissions,
          agencyId: rolePermissions?.agencyId,
          isFbTokenExpired,
          ...connectedStores,
        };
      });
      return await Promise.all(promises);
    }
    return [];
  } catch (e) {
    console.error("ERROR GETTING STORES", e);
    throw e;
  }
}

async function checkFacebookToken(userId: string, storeId: string) {
  const fbToken = await getFacebookAccessToken(userId, storeId);
  return fbToken && typeof fbToken !== "string" && "customError" in fbToken
    ? fbToken.customError.errorType === ErrorType.ACCESS_TOKEN_EXPIRED
    : false;
}

export async function saveStore({
  storeId,
  storeParams,
}: {
  storeId: string;
  storeParams: FormTypes & {
    createdAt?: string;
    updatedAt?: string;
    owner?: string[];
  };
}) {
  const updateExpression = `SET ${Object.keys(storeParams)
    .map((key) => `#${key} = :${key}`)
    .join(", ")}`;

  const expressionAttributeValues = Object.entries(storeParams).reduce(
    (result, [key, value]) => ({
      ...result,
      [`:${key}`]: value,
    }),
    {},
  );

  const expressionAttributeNames = Object.keys(storeParams).reduce(
    (result, key) => ({
      ...result,
      [`#${key}`]: key,
    }),
    {},
  );

  try {
    const data = await db.update({
      TableName: TABLE_NAME,
      Key: {
        id: storeId,
      },
      UpdateExpression: updateExpression,
      ExpressionAttributeValues: expressionAttributeValues,
      ExpressionAttributeNames: expressionAttributeNames,
      ReturnValues: "ALL_NEW",
    });

    return data?.Attributes;
  } catch (e: any) {
    console.log("SAVE STORE ERROR!!!", e.message, e.code);
    return null;
  }
}

export async function getKlaviyoApiKey(storeId: string) {
  const store = await getStoreItem(storeId, ["klaviyoAPIKey"]);

  if (!store) {
    return null;
  }

  return (store as Store).klaviyoAPIKey;
}

export async function getReChargeApiKey(storeId: string) {
  const store = await getStoreItem(storeId, ["rechargeAPIKey"]);

  if (!store) {
    return null;
  }

  return (store as Store).rechargeAPIKey;
}

export async function getOwnersStoreNamePartnerByStoreId(storeId: string) {
  if (!storeId) return null;
  try {
    const { Item } = await db.get({
      TableName: TABLE_NAME,
      Key: {
        id: storeId,
      },
      AttributesToGet: ["owner", "storeName", "linkedPartnerId"],
    });

    if (!Item) {
      return null;
    }

    return {
      owners: (Item as Store).owner,
      storeName: (Item as Store).storeName,
      linkedPartnerId: (Item as Store).linkedPartnerId,
    };
  } catch (e) {
    return null;
  }
}

export async function getStoreNameByStoreId(storeId: string) {
  if (!storeId) return null;
  try {
    const { Item } = await db.get({
      TableName: TABLE_NAME,
      Key: {
        id: storeId,
      },
      AttributesToGet: ["storeName"],
    });

    if (!Item) {
      return null;
    }

    return (Item as Store).storeName;
  } catch (e) {
    console.error("ERROR GETTING STORE NAME", e);
    return null;
  }
}

export async function getStoreNameAndPartnerByStoreId(storeId: string) {
  if (!storeId) return null;
  try {
    const { Item } = await db.get({
      TableName: TABLE_NAME,
      Key: {
        id: storeId,
      },
      AttributesToGet: ["storeName", "linkedPartnerId"],
    });

    if (!Item) {
      return null;
    }

    return {
      storeName: (Item as Store).storeName,
      linkedPartnerId: (Item as Store).linkedPartnerId,
    };
  } catch (e) {
    console.error("ERROR GETTING STORE NAME", e);
    return null;
  }
}

export async function addStoreOwner(storeId: string, userId: string) {
  try {
    await db.update({
      TableName: TABLE_NAME,
      Key: {
        id: storeId,
      },
      UpdateExpression: "set #owner = list_append(if_not_exists(#owner, :empty_list), :userId)",
      ExpressionAttributeNames: {
        "#owner": "owner",
      },
      ExpressionAttributeValues: {
        ":userId": [userId],
        ":empty_list": [],
      },
      ReturnValues: "UPDATED_NEW",
    });
  } catch (e) {
    console.log("ERROR ADDING STORE OWNER", e);
    throw new Error("Error adding store owner");
  }
}

export async function deleteStoreOwner(storeId: string, userId: string) {
  try {
    // First, get the current owners
    const { Item } = await db.get({
      TableName: TABLE_NAME,
      Key: {
        id: storeId,
      },
    });

    if (!Item || !Item.owner) {
      throw new Error("Store or owner not found");
    }

    // Find the index of the user to remove
    const index = Item.owner.indexOf(userId);

    if (index === -1) {
      throw new Error("User not found in owner list");
    }

    // Then, remove the user from the owners
    await db.update({
      TableName: TABLE_NAME,
      Key: {
        id: storeId,
      },
      UpdateExpression: `remove #owner[${index}]`,
      ExpressionAttributeNames: {
        "#owner": "owner",
      },
      ReturnValues: "UPDATED_NEW",
    });
  } catch (e) {
    console.log("ERROR DELETING STORE OWNER", e);
    throw new Error("Error deleting store owner");
  }
}

export async function getAllStores(): Promise<Store[] | null> {
  try {
    const { Items } = await db.scan({
      TableName: TABLE_NAME,
    });

    if (!Items || Items.length === 0) {
      return null;
    }

    return Items as Store[];
  } catch (e) {
    console.log("ERROR", e);
    return null;
  }
}

export async function deleteStore(storeId: string) {
  try {
    await db.delete({
      TableName: TABLE_NAME,
      Key: {
        id: storeId,
      },
    });
    return `Deleted store with id: ${storeId}`;
  } catch (e) {
    console.log("ERROR DELETING STORE", e);
    return `Error deleting store with id: ${storeId}`;
  }
}

export async function setLinkedPartner(storeId: string, partnerId: string) {
  try {
    await db.update({
      TableName: TABLE_NAME,
      Key: {
        id: storeId,
      },
      UpdateExpression: "set #linkedPartnerId = :partnerId",
      ExpressionAttributeNames: {
        "#linkedPartnerId": "linkedPartnerId",
      },
      ExpressionAttributeValues: {
        ":partnerId": partnerId,
      },
      ReturnValues: "UPDATED_NEW",
    });
  } catch (e) {
    console.log("ERROR SETTING LINKED PARTNER", e);
    throw new Error("Error setting linked partner");
  }
}

export async function removeLinkedPartnerFromStore(storeId: string) {
  try {
    await db.update({
      TableName: TABLE_NAME,
      Key: {
        id: storeId,
      },
      UpdateExpression: "remove #linkedPartnerId",
      ExpressionAttributeNames: {
        "#linkedPartnerId": "linkedPartnerId",
      },
      ReturnValues: "UPDATED_NEW",
    });
  } catch (e) {
    console.log("ERROR UNLINKING PARTNER", e);
    throw new Error("Error unlinking partner");
  }
}

export async function updateOnboardingStatus(storeId: string, status: boolean) {
  try {
    await db.update({
      TableName: TABLE_NAME,
      Key: {
        id: storeId,
      },
      UpdateExpression: "set #isOnboarding = :status",
      ExpressionAttributeNames: {
        "#isOnboarding": "isOnboarding",
      },
      ExpressionAttributeValues: {
        ":status": status,
      },
      ReturnValues: "UPDATED_NEW",
    });
  } catch (e) {
    console.log("ERROR UPDATING ONBOARDING STATUS", e);
    throw new Error("Error updating onboarding status");
  }
}

export async function getConnectedAdsChannels(storeId: string) {
  const store = await getStoreItem(storeId, ["facebookAdsAccount", "googleAdsAccount", "tiktokID"]);

  if (!store) {
    return null;
  }

  return {
    isFacebookAdsConnected: (store as Store).facebookAdsAccount?.id !== undefined,
    isGoogleAdsConnected: (store as Store).googleAdsAccount?.id !== undefined,
    isTikTokConnected: (store as Store).tiktokID !== undefined,
  };
}

export async function batchGetStoresByIds(storeIds: string[], attributesToGet?: string[]) {
  try {
    const chunks = chunkArray(storeIds, 100);
    const promises = chunks.map(async (chunk) => {
      const keys = chunk.map((id) => ({ id }));
      const { Responses } = await db.batchGet({
        RequestItems: {
          [TABLE_NAME]: {
            Keys: keys,
            ...(attributesToGet && { ProjectionExpression: attributesToGet.join(",") }),
          },
        },
      });

      return Responses?.[TABLE_NAME] || [];
    });

    const results = await Promise.all(promises);

    return results.flat();
  } catch (error) {
    console.error("Error in batchGetStoresByIds:", error);
    throw new Error("Failed to batch get stores by IDs");
  }
}
