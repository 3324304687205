interface BenchmarksPayload {
  viewId: string | undefined;
  customerId: string | undefined;
  managerCustomerId: string | undefined;
  accountId: string | undefined;
  industryId: string | undefined;
  storeId: string | undefined;
}

const getQuery = (enpoint: string, payload: BenchmarksPayload) => {
  const {
    viewId,
    customerId,
    managerCustomerId,
    accountId,
    industryId,
    storeId,
  } = payload;
  return `${enpoint}?viewId=${viewId}&customerId=${customerId}&accountId=${accountId}&industryId=${industryId}&managerCustomerId=${managerCustomerId}&storeId=${storeId}`;
}

export enum BenchmarksEndpoints {
  "facebookAds" = "facebookAds",
  "googleAds" = "googleAds",
  "storePerformance" = "storePerformance",
};

const ENDPOINTS: Record<BenchmarksEndpoints, any> = {
  [BenchmarksEndpoints.googleAds]: (payload: BenchmarksPayload) => getQuery("/api/benchmarks/google-ads", payload),
  [BenchmarksEndpoints.facebookAds]: (payload: BenchmarksPayload) => getQuery("/api/benchmarks/facebook-ads", payload),
  [BenchmarksEndpoints.storePerformance]: (payload: BenchmarksPayload) => getQuery("/api/benchmarks/store-performance", payload),
};

export { ENDPOINTS };
