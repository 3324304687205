type Agency = {
  partnerId: string;
  name: string;
};

type PartnerDomainAgencyMap = {
  [domain: string]: Agency | undefined;
};

export const partnerDomainAgencyMap: PartnerDomainAgencyMap = {
  "insights.getmejuice.com": { partnerId: "1c1e914b-25e3-4dac-93d7-8f19ea878224", name: "Juice" },
  "marketinganalytics.returnsportal.co": {
    partnerId: "0fb237cf-4c27-4157-bb4d-6ababdf6eff7",
    name: "Swap",
  },
  "app.quantaxco.com": { partnerId: "a020c12d-6b6f-4521-a213-4a6dcdee3ab8", name: "Quantaxco" },
  "platform.paase.ai": { partnerId: "41316fd4-b106-46dc-82e2-e49b61b0fd9a", name: "PAASE" },
};
