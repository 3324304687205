const getParams = (storeId?: string | null, partnerId?: string | null) => {
  if (partnerId) return `?partnerId=${partnerId}`;
  if (storeId) return `?storeId=${storeId}`;
};

const ENDPOINTS = {
  getWhiteLabelItem: ({
    storeId,
    partnerId,
  }: {
    storeId?: string | null;
    partnerId?: string | null;
  }) => `/api/whitelabel${getParams(storeId, partnerId)}`,
  getAgencyStores: (storeIds: string) => `/api/whitelabel/agency-stores?storeIds=${storeIds}`,
  saveAgencyBranding: () => `/api/whitelabel`,
  saveStoreBranding: () => `/api/whitelabel`,
};

const QUERY_KEYS = {
  AGENCY_STORES: "agencyStores",
};

export { ENDPOINTS, QUERY_KEYS };
