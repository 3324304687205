import { GroupByOption } from "../../context/DateDropdownContext";

const ENDPOINTS = {
  auth: () => "/api/klaviyo/auth",
  cardsAndChartDataForPeriod: ({
    from,
    to,
    prevStartDate,
    prevEndDate,
    storeId,
    groupByValue,
  }: {
    from: string;
    to: string;
    prevStartDate: string;
    prevEndDate: string;
    storeId: string | undefined;
    groupByValue: GroupByOption;
  }) =>
    `/api/klaviyo/cards?storeId=${storeId}&startDate=${from}&endDate=${to}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}&groupBy=${groupByValue}`,
  table: ({ from, to, storeId }: { from: string; to: string; storeId: string | undefined }) =>
    `/api/klaviyo/table?startDate=${from}&endDate=${to}&storeId=${storeId}`,
};

export { ENDPOINTS };
