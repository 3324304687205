const ENDPOINTS = {
  summary: (storeId: string | undefined) => `/api/ga4/summary?storeId=${storeId}`,
  cardsAndChartDataForPeriod: ({
    id,
    from,
    to,
    prevStartDate,
    prevEndDate,
    storeId,
  }: {
    id: string;
    from: string;
    to: string;
    prevStartDate: string;
    prevEndDate: string;
    storeId: string | undefined;
  }) =>
    `/api/ga4/cards?storeId=${storeId}&propertyId=${id}&startDate=${from}&endDate=${to}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`,
  funnelChartDataForPeriod: ({
    id,
    from,
    to,
    storeId,
  }: {
    id: string;
    from: string;
    to: string;
    storeId: string | undefined;
  }) => `/api/ga4/funnel?storeId=${storeId}&propertyId=${id}&startDate=${from}&endDate=${to}`,
  userBySourceDataForPeriod: ({
    id,
    from,
    to,
    storeId,
  }: {
    id: string;
    from: string;
    to: string;
    storeId: string | undefined;
  }) =>
    `/api/ga4/users-by-source?storeId=${storeId}&propertyId=${id}&startDate=${from}&endDate=${to}`,
  transactionByProductDataForPeriod: ({
    id,
    from,
    to,
    storeId,
    prevStartDate,
    prevEndDate,
  }: {
    id: string;
    from: string;
    to: string;
    prevStartDate: string;
    prevEndDate: string;
    storeId: string | undefined;
  }) =>
    `/api/ga4/transactions-by-product?storeId=${storeId}&propertyId=${id}&startDate=${from}&endDate=${to}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`,
  transactionBySourceDataForPeriod: ({
    id,
    from,
    to,
    storeId,
    prevStartDate,
    prevEndDate,
  }: {
    id: string;
    from: string;
    to: string;
    prevStartDate: string;
    prevEndDate: string;
    storeId: string | undefined;
  }) =>
    `/api/ga4/transactions-by-source?storeId=${storeId}&propertyId=${id}&startDate=${from}&endDate=${to}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`,
};

export { ENDPOINTS };
