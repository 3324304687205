import { queryFetcher } from './index';

const ENDPOINTS = {
  authState: () => '/api/woocommerce/auth-state',
  woocommerceCheckout: () => '/api/woocommerce/checkout',
  data: ({
    from,
    to,
    username,
  }: {
    from: string;
    to: string;
    username: string;
  }) => `/api/shopify?from=${from}&to=${to}&username=${username}`,
};

function getAuthState<T>(payload: T) {
  return queryFetcher(ENDPOINTS.authState(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

function getWoocommerceCheckoutURL<T>(payload: T) {
  return queryFetcher(ENDPOINTS.woocommerceCheckout(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export { ENDPOINTS, getWoocommerceCheckoutURL, getAuthState };
