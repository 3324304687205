import numeral from "numeral";
import { format, parseISO } from "date-fns";

export function roundNumber(value: number | string): string {
  const number = +value;
  if (Number.isInteger(number)) return number.toString();
  return number.toFixed(2);
}

export const addFormatting = (number: number | string): string => {
  if (Number.isInteger(+number)) {
    return numeral(+number).format("0,0");
  }

  return numeral(roundNumber(number)).format("0,0.00");
};

export const addFormattingUptoTwoDecimals = (number: number | string): string => {
  if (Number.isInteger(+number)) {
    return numeral(+number).format("0,0.00");
  }

  return numeral(roundNumber(number)).format("0,0.00");
};

export const removeFormatting = (rawNumber: string): number =>
  +rawNumber.toString().replace(/[^-0-9.]/g, "");

export const formatDDMMYYYY = (date: string): string => {
  const initialDate = parseISO(date);
  return format(initialDate, "dd-MM-yyyy");
};
