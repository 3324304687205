import { queryFetcher } from "./index";

const ENDPOINTS = {
  store: () => "/api/store",
  update: () => "/api/store/update",
  finishOnboarding: () => "/api/store/finish-onboarding",
};

function createStore<T>(payload: T) {
  return queryFetcher(ENDPOINTS.store(), {
    method: "POST",
    body: JSON.stringify(payload),
  });
}

function updateStore<T>(payload: T) {
  return queryFetcher(ENDPOINTS.update(), {
    method: "POST",
    body: JSON.stringify(payload),
  });
}

function finishOnboarding<T>(storeId: T) {
  return queryFetcher(ENDPOINTS.finishOnboarding(), {
    method: "POST",
    body: JSON.stringify({
      storeId,
    }),
  });
}

export { ENDPOINTS, createStore, updateStore, finishOnboarding };
