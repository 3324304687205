export const lorem10 =
  "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa, distinctio?";
export const lorem30 =
  "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci beatae consequatur itaque iure laborum minus natus placeat provident totam? Doloremque eos harum ipsa, libero obcaecati perspiciatis repellendus tempore voluptatibus.";

export enum DateOptions {
  Today,
  Yesterday,
  LastSevenDays,
  LastWeek,
  MTD,
  LastThirtyDays,
  LastSixtyDays,
  LastNinetyDays,
  LastOneTwentyDays,
  LastThreeSixtyFiveDays,
  LastMonth,
  LastThreeMonths,
  LastSixMonths,
  LastNineMonths,
  LastTwelveMonths,
  LastTwentyFourMonths,
  LastThirtySixMonths,
  LastYear,
  YTD,
  Custom,
}

export const DATE_OPTIONS = [
  "Today",
  "Yesterday",
  "Last 7 days",
  "Last Week",
  "Month to Date",
  "Last 30 days",
  "Last 60 days",
  "Last 90 days",
  "Last 120 days",
  "Last 365 days",
  "Last Month",
  "Last 3 Months",
  "Last 6 Months",
  "Last 9 Months",
  "Last 12 Months",
  "Last 24 Months",
  "Last 36 Months",
  "Last Year",
  "Year to Date",
  "Custom",
] as const;

export type DataOptionsType =
  | "Today"
  | "Yesterday"
  | "Last 7 days"
  | "Last Week"
  | "Month to Date"
  | "Last 30 days"
  | "Last 60 days"
  | "Last 90 days"
  | "Last 120 days"
  | "Last 365 days"
  | "Last Month"
  | "Last 3 Months"
  | "Last 6 Months"
  | "Last 9 Months"
  | "Last 12 Months"
  | "Last 24 Months"
  | "Last 36 Months"
  | "Last Year"
  | "Year to Date"
  | "Custom";

export const COUNTRIES = [
  { id: 0, code: "AD", title: "Andorra" },
  { id: 1, code: "AE", title: "United Arab Emirates" },
  { id: 2, code: "AF", title: "Afghanistan" },
  { id: 3, code: "AG", title: "Antigua and Barbuda" },
  { id: 4, code: "AI", title: "Anguilla" },
  { id: 5, code: "AL", title: "Albania" },
  { id: 6, code: "AM", title: "Armenia" },
  { id: 7, code: "AO", title: "Angola" },
  { id: 8, code: "AQ", title: "Antarctica" },
  { id: 9, code: "AR", title: "Argentina" },
  { id: 10, code: "AS", title: "American Samoa" },
  { id: 11, code: "AT", title: "Austria" },
  { id: 12, code: "AU", title: "Australia" },
  { id: 13, code: "AW", title: "Aruba" },
  { id: 14, code: "AX", title: "Åland Islands" },
  { id: 15, code: "AZ", title: "Azerbaijan" },
  { id: 16, code: "BA", title: "Bosnia and Herzegovina" },
  { id: 17, code: "BB", title: "Barbados" },
  { id: 18, code: "BD", title: "Bangladesh" },
  { id: 19, code: "BE", title: "Belgium" },
  { id: 20, code: "BF", title: "Burkina Faso" },
  { id: 21, code: "BG", title: "Bulgaria" },
  { id: 22, code: "BH", title: "Bahrain" },
  { id: 23, code: "BI", title: "Burundi" },
  { id: 24, code: "BJ", title: "Benin" },
  { id: 25, code: "BL", title: "Saint Barthélemy" },
  { id: 26, code: "BM", title: "Bermuda" },
  { id: 27, code: "BN", title: "Brunei Darussalam" },
  { id: 28, code: "BO", title: "Bolivia, Plurinational State of" },
  { id: 29, code: "BQ", title: "Bonaire, Sint Eustatius and Saba" },
  { id: 30, code: "BR", title: "Brazil" },
  { id: 31, code: "BS", title: "Bahamas" },
  { id: 32, code: "BT", title: "Bhutan" },
  { id: 33, code: "BV", title: "Bouvet Island" },
  { id: 34, code: "BW", title: "Botswana" },
  { id: 35, code: "BY", title: "Belarus" },
  { id: 36, code: "BZ", title: "Belize" },
  { id: 37, code: "CA", title: "Canada" },
  { id: 38, code: "CC", title: "Cocos (Keeling) Islands" },
  { id: 39, code: "CD", title: "Congo, Democratic Republic of the" },
  { id: 40, code: "CF", title: "Central African Republic" },
  { id: 41, code: "CG", title: "Congo" },
  { id: 42, code: "CH", title: "Switzerland" },
  { id: 43, code: "CI", title: "Côte d'Ivoire" },
  { id: 44, code: "CK", title: "Cook Islands" },
  { id: 45, code: "CL", title: "Chile" },
  { id: 46, code: "CM", title: "Cameroon" },
  { id: 47, code: "CN", title: "China" },
  { id: 48, code: "CO", title: "Colombia" },
  { id: 49, code: "CR", title: "Costa Rica" },
  { id: 50, code: "CU", title: "Cuba" },
  { id: 51, code: "CV", title: "Cabo Verde" },
  { id: 52, code: "CW", title: "Curaçao" },
  { id: 53, code: "CX", title: "Christmas Island" },
  { id: 54, code: "CY", title: "Cyprus" },
  { id: 55, code: "CZ", title: "Czechia" },
  { id: 56, code: "DE", title: "Germany" },
  { id: 57, code: "DJ", title: "Djibouti" },
  { id: 58, code: "DK", title: "Denmark" },
  { id: 59, code: "DM", title: "Dominica" },
  { id: 60, code: "DO", title: "Dominican Republic" },
  { id: 61, code: "DZ", title: "Algeria" },
  { id: 62, code: "EC", title: "Ecuador" },
  { id: 63, code: "EE", title: "Estonia" },
  { id: 64, code: "EG", title: "Egypt" },
  { id: 65, code: "EH", title: "Western Sahara" },
  { id: 66, code: "ER", title: "Eritrea" },
  { id: 67, code: "ES", title: "Spain" },
  { id: 68, code: "ET", title: "Ethiopia" },
  { id: 69, code: "FI", title: "Finland" },
  { id: 70, code: "FJ", title: "Fiji" },
  { id: 71, code: "FK", title: "Falkland Islands (Malvinas)" },
  { id: 72, code: "FM", title: "Micronesia, Federated States of" },
  { id: 73, code: "FO", title: "Faroe Islands" },
  { id: 74, code: "FR", title: "France" },
  { id: 75, code: "GA", title: "Gabon" },
  { id: 76, code: "GB", title: "United Kingdom of Great Britain and Northern Ireland" },
  { id: 77, code: "GD", title: "Grenada" },
  { id: 78, code: "GE", title: "Georgia" },
  { id: 79, code: "GF", title: "French Guiana" },
  { id: 80, code: "GG", title: "Guernsey" },
  { id: 81, code: "GH", title: "Ghana" },
  { id: 82, code: "GI", title: "Gibraltar" },
  { id: 83, code: "GL", title: "Greenland" },
  { id: 84, code: "GM", title: "Gambia" },
  { id: 85, code: "GN", title: "Guinea" },
  { id: 86, code: "GP", title: "Guadeloupe" },
  { id: 87, code: "GQ", title: "Equatorial Guinea" },
  { id: 88, code: "GR", title: "Greece" },
  { id: 89, code: "GS", title: "South Georgia and the South Sandwich Islands" },
  { id: 90, code: "GT", title: "Guatemala" },
  { id: 91, code: "GU", title: "Guam" },
  { id: 92, code: "GW", title: "Guinea-Bissau" },
  { id: 93, code: "GY", title: "Guyana" },
  { id: 94, code: "HK", title: "Hong Kong" },
  { id: 95, code: "HM", title: "Heard Island and McDonald Islands" },
  { id: 96, code: "HN", title: "Honduras" },
  { id: 97, code: "HR", title: "Croatia" },
  { id: 98, code: "HT", title: "Haiti" },
  { id: 99, code: "HU", title: "Hungary" },
  { id: 100, code: "ID", title: "Indonesia" },
  { id: 101, code: "IE", title: "Ireland" },
  { id: 102, code: "IL", title: "Israel" },
  { id: 103, code: "IM", title: "Isle of Man" },
  { id: 104, code: "IN", title: "India" },
  { id: 105, code: "IO", title: "British Indian Ocean Territory" },
  { id: 106, code: "IQ", title: "Iraq" },
  { id: 107, code: "IR", title: "Iran, Islamic Republic of" },
  { id: 108, code: "IS", title: "Iceland" },
  { id: 109, code: "IT", title: "Italy" },
  { id: 110, code: "JE", title: "Jersey" },
  { id: 111, code: "JM", title: "Jamaica" },
  { id: 112, code: "JO", title: "Jordan" },
  { id: 113, code: "JP", title: "Japan" },
  { id: 114, code: "KE", title: "Kenya" },
  { id: 115, code: "KG", title: "Kyrgyzstan" },
  { id: 116, code: "KH", title: "Cambodia" },
  { id: 117, code: "KI", title: "Kiribati" },
  { id: 118, code: "KM", title: "Comoros" },
  { id: 119, code: "KN", title: "Saint Kitts and Nevis" },
  { id: 120, code: "KP", title: "Korea, Democratic People's Republic of" },
  { id: 121, code: "KR", title: "Korea, Republic of" },
  { id: 122, code: "KW", title: "Kuwait" },
  { id: 123, code: "KY", title: "Cayman Islands" },
  { id: 124, code: "KZ", title: "Kazakhstan" },
  { id: 125, code: "LA", title: "Lao People's Democratic Republic" },
  { id: 126, code: "LB", title: "Lebanon" },
  { id: 127, code: "LC", title: "Saint Lucia" },
  { id: 128, code: "LI", title: "Liechtenstein" },
  { id: 129, code: "LK", title: "Sri Lanka" },
  { id: 130, code: "LR", title: "Liberia" },
  { id: 131, code: "LS", title: "Lesotho" },
  { id: 132, code: "LT", title: "Lithuania" },
  { id: 133, code: "LU", title: "Luxembourg" },
  { id: 134, code: "LV", title: "Latvia" },
  { id: 135, code: "LY", title: "Libya" },
  { id: 136, code: "MA", title: "Morocco" },
  { id: 137, code: "MC", title: "Monaco" },
  { id: 138, code: "MD", title: "Moldova, Republic of" },
  { id: 139, code: "ME", title: "Montenegro" },
  { id: 140, code: "MF", title: "Saint Martin, (French part)" },
  { id: 141, code: "MG", title: "Madagascar" },
  { id: 142, code: "MH", title: "Marshall Islands" },
  { id: 143, code: "MK", title: "North Macedonia" },
  { id: 144, code: "ML", title: "Mali" },
  { id: 145, code: "MM", title: "Myanmar" },
  { id: 146, code: "MN", title: "Mongolia" },
  { id: 147, code: "MO", title: "Macao" },
  { id: 148, code: "MP", title: "Northern Mariana Islands" },
  { id: 149, code: "MQ", title: "Martinique" },
  { id: 150, code: "MR", title: "Mauritania" },
  { id: 151, code: "MS", title: "Montserrat" },
  { id: 152, code: "MT", title: "Malta" },
  { id: 153, code: "MU", title: "Mauritius" },
  { id: 154, code: "MV", title: "Maldives" },
  { id: 155, code: "MW", title: "Malawi" },
  { id: 156, code: "MX", title: "Mexico" },
  { id: 157, code: "MY", title: "Malaysia" },
  { id: 158, code: "MZ", title: "Mozambique" },
  { id: 159, code: "NA", title: "Namibia" },
  { id: 160, code: "NC", title: "New Caledonia" },
  { id: 161, code: "NE", title: "Niger" },
  { id: 162, code: "NF", title: "Norfolk Island" },
  { id: 163, code: "NG", title: "Nigeria" },
  { id: 164, code: "NI", title: "Nicaragua" },
  { id: 165, code: "NL", title: "Netherlands" },
  { id: 166, code: "NO", title: "Norway" },
  { id: 167, code: "NP", title: "Nepal" },
  { id: 168, code: "NR", title: "Nauru" },
  { id: 169, code: "NU", title: "Niue" },
  { id: 170, code: "NZ", title: "New Zealand" },
  { id: 171, code: "OM", title: "Oman" },
  { id: 172, code: "PA", title: "Panama" },
  { id: 173, code: "PE", title: "Peru" },
  { id: 174, code: "PF", title: "French Polynesia" },
  { id: 175, code: "PG", title: "Papua New Guinea" },
  { id: 176, code: "PH", title: "Philippines" },
  { id: 177, code: "PK", title: "Pakistan" },
  { id: 178, code: "PL", title: "Poland" },
  { id: 179, code: "PM", title: "Saint Pierre and Miquelon" },
  { id: 180, code: "PN", title: "Pitcairn" },
  { id: 181, code: "PR", title: "Puerto Rico" },
  { id: 182, code: "PS", title: "Palestine, State of" },
  { id: 183, code: "PT", title: "Portugal" },
  { id: 184, code: "PW", title: "Palau" },
  { id: 185, code: "PY", title: "Paraguay" },
  { id: 186, code: "QA", title: "Qatar" },
  { id: 187, code: "RE", title: "Réunion" },
  { id: 188, code: "RO", title: "Romania" },
  { id: 189, code: "RS", title: "Serbia" },
  { id: 190, code: "RU", title: "Russian Federation" },
  { id: 191, code: "RW", title: "Rwanda" },
  { id: 192, code: "SA", title: "Saudi Arabia" },
  { id: 193, code: "SB", title: "Solomon Islands" },
  { id: 194, code: "SC", title: "Seychelles" },
  { id: 195, code: "SD", title: "Sudan" },
  { id: 196, code: "SE", title: "Sweden" },
  { id: 197, code: "SG", title: "Singapore" },
  { id: 198, code: "SH", title: "Saint Helena, Ascension and Tristan da Cunha" },
  { id: 199, code: "SI", title: "Slovenia" },
  { id: 200, code: "SJ", title: "Svalbard and Jan Mayen" },
  { id: 201, code: "SK", title: "Slovakia" },
  { id: 202, code: "SL", title: "Sierra Leone" },
  { id: 203, code: "SM", title: "San Marino" },
  { id: 204, code: "SN", title: "Senegal" },
  { id: 205, code: "SO", title: "Somalia" },
  { id: 206, code: "SR", title: "Suriname" },
  { id: 207, code: "SS", title: "South Sudan" },
  { id: 208, code: "ST", title: "Sao Tome and Principe" },
  { id: 209, code: "SV", title: "El Salvador" },
  { id: 210, code: "SX", title: "Sint Maarten, (Dutch part)" },
  { id: 211, code: "SY", title: "Syrian Arab Republic" },
  { id: 212, code: "SZ", title: "Eswatini" },
  { id: 213, code: "TC", title: "Turks and Caicos Islands" },
  { id: 214, code: "TD", title: "Chad" },
  { id: 215, code: "TF", title: "French Southern Territories" },
  { id: 216, code: "TG", title: "Togo" },
  { id: 217, code: "TH", title: "Thailand" },
  { id: 218, code: "TJ", title: "Tajikistan" },
  { id: 219, code: "TK", title: "Tokelau" },
  { id: 220, code: "TL", title: "Timor-Leste" },
  { id: 221, code: "TM", title: "Turkmenistan" },
  { id: 222, code: "TN", title: "Tunisia" },
  { id: 223, code: "TO", title: "Tonga" },
  { id: 224, code: "TR", title: "Türkiye" },
  { id: 225, code: "TT", title: "Trinidad and Tobago" },
  { id: 226, code: "TV", title: "Tuvalu" },
  { id: 227, code: "TW", title: "Taiwan, Province of China" },
  { id: 228, code: "TZ", title: "Tanzania, United Republic of" },
  { id: 229, code: "UA", title: "Ukraine" },
  { id: 230, code: "UG", title: "Uganda" },
  { id: 231, code: "UM", title: "United States Minor Outlying Islands" },
  { id: 232, code: "US", title: "United States of America" },
  { id: 233, code: "UY", title: "Uruguay" },
  { id: 234, code: "UZ", title: "Uzbekistan" },
  { id: 235, code: "VA", title: "Holy See" },
  { id: 236, code: "VC", title: "Saint Vincent and the Grenadines" },
  { id: 237, code: "VE", title: "Venezuela, Bolivarian Republic of" },
  { id: 238, code: "VG", title: "Virgin Islands, British" },
  { id: 239, code: "VI", title: "Virgin Islands, U.S." },
  { id: 240, code: "VN", title: "Viet Nam" },
  { id: 241, code: "VU", title: "Vanuatu" },
  { id: 242, code: "WF", title: "Wallis and Futuna" },
  { id: 243, code: "WS", title: "Samoa" },
  { id: 244, code: "YE", title: "Yemen" },
  { id: 245, code: "YT", title: "Mayotte" },
  { id: 246, code: "ZA", title: "South Africa" },
  { id: 247, code: "ZM", title: "Zambia" },
  { id: 248, code: "ZW", title: "Zimbabwe" },
];

export const removeDuplicates = (arr: any[], prop: string) => {
  const uniqueMap = new Map();

  arr.forEach((obj) => {
    const value = obj[prop];
    if (!uniqueMap.has(value)) {
      uniqueMap.set(value, []);
    }
    uniqueMap.get(value).push(obj);
  });

  const result: any[] = [];
  uniqueMap.forEach((group) => {
    if (group.length === 1) {
      result.push(group[0]);
    }
  });

  return result;
};
