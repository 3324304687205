import mixpanel from "mixpanel-browser";
import { FormTypes } from "../pages/Onboarding/types/types";
import { MixpanelEvents } from "../types/mixpanel";

export { Pages } from "../types/mixpanel";

export class MixpanelTracking {
  private static instance: MixpanelTracking;

  public static getInstance(): MixpanelTracking {
    if (MixpanelTracking.instance == null) {
      const newInstance = new MixpanelTracking();
      MixpanelTracking.instance = newInstance;

      return newInstance;
    }

    return this.instance;
  }

  public constructor() {
    if (MixpanelTracking.instance)
      throw new Error(
        "Error: Instance creation of MixpanelTracking is not allowed! Use getInstance",
      );
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_ID || "", {
      ignore_dnt: true,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  protected track(name: string, data: any = {}) {
    mixpanel.track(name, data);
  }

  // eslint-disable-next-line class-methods-use-this
  protected identify(id: string) {
    mixpanel.identify(id);
  }

  // eslint-disable-next-line class-methods-use-this
  protected reset() {
    mixpanel.reset();
  }

  // eslint-disable-next-line class-methods-use-this
  protected alias(id: string) {
    mixpanel.alias(id);
  }

  // eslint-disable-next-line class-methods-use-this
  protected peopleSet(email: string, name: string) {
    mixpanel.people.set({
      $email: email,
      $name: name,
    });
  }

  public identifyNewUser(id: string) {
    this.identify(id);
  }

  public resetSession() {
    this.reset();
  }

  public registerNewUser(id: string) {
    this.alias(id);
  }

  public pageVisited(page: string) {
    this.track(MixpanelEvents.PAGE_VISIT, { page });
  }

  public onboardingCompleted(onboarding: FormTypes) {
    this.track(MixpanelEvents.ONBOARDING_COMPLETE, { onboarding });
  }

  public dateRange(from: string, to: string) {
    this.track(MixpanelEvents.DATE_RANGE, { from, to });
  }

  public connectWithChannel(connect: string, store: string) {
    this.track(MixpanelEvents.CONNECT_WITH_CHANNEL, { connect, store });
  }

  public setOnboardingStep(newStep: number) {
    this.track(MixpanelEvents.ONBOARDING_STEP, { newStep });
  }

  public setUser(email: string, name: string) {
    this.peopleSet(email, name);
  }
}
