import { LoadingCardId } from "../types";
import { PinnedCards } from "../../../lib/pinned/types";
import { getObjectKeyBySource, transformObjectKeys } from "./helpers";

export const deleteCardFromState = (
  prevState: PinnedCards,
  { source: oldSource, cardKey }: LoadingCardId,
) => {
  const source = getObjectKeyBySource(oldSource);
  const sourceToChange = prevState[source];
  const { [cardKey as keyof typeof sourceToChange]: propertyToDelete, ...newSourceObject } =
    sourceToChange;

  const result = {
    ...prevState,
    [source]: newSourceObject,
  };

  return transformObjectKeys(result) as PinnedCards;
};
