import { Card, Chart, Period, PinnedCard } from "../../types";

export type KlaviyoResponseAPI<T> = {
  data: Array<T>;
  links: {
    self: string;
    next: null | string;
    prev: null | string;
  };
};

export type KlaviyoMetrics =
  | "clickedEmail"
  | "receivedEmail"
  | "unsubscribed"
  | "placedOrder"
  | "subscribed";

export type KlaviyoMetricInfo = {
  id: string;
  attributes: {
    name: string;
    created: string;
    updated: string;
    integration: {
      name: string;
    };
  };
  links: {
    self: string;
  };
};

type KlaviyoDefaultParams = Period & {
  apiKey: string;
};

export type KlaviyoCardsParams = KlaviyoDefaultParams & {
  metrics: Record<KlaviyoMetrics, string>;
};

export type KlaviyoTableParams = KlaviyoDefaultParams & {
  metrics: Record<"placedOrder" | "receivedEmail", string>;
};

type Measurement = "sum_value" | "count";

export type QueryMetricsParams = {
  apiKey: string;
  metricId: string;
  measurement: Measurement;
  startDate: string;
  endDate: string;
  by?: string;
};

export type KlaviyoMetricsQueryResponseAPI = {
  data: {
    type: "metric-aggregate";
    id: string;
    attributes: {
      dates: string[];
      data: {
        dimensions: string[];
        measurements: {
          [key in Measurement]: number[];
        };
      }[];
    };
    links: {
      self: string;
    };
  };
  links: {
    self: string;
    next: null | string;
    prev: null | string;
  };
  errors?: {
    id: string;
    status: number;
    code: string;
    title: string;
    detail: string;
  }[];
};

export type KlaviyoMetricsCountDbResponse = {
  id: number;
  c_date: string;
  click_count: string;
  received_count: string;
  unsub_count: string;
  sub_count: string;
  store_id: string;
};

export type KlaviyoCampaignsInfo = {
  id: string;
  attributes: {
    name: string;
    status: string;
    archived: boolean;
    created_at: string;
    scheduled_at: string;
    updated_at: string;
    send_time: string;
  };
};

export type KlaviyoDbCampaignsInfo = {
  id: number;
  campaign_date: string;
  campaign_id: string;
  name: string;
  status: string;
  type: string;
  recipient_count: string;
  store_id: string;
};

export type KlaviyoCountMetrics = {
  id: number;
  c_date: string | null;
  click_count: string | null;
  received_count: string | null;
  open_count: string | null;
  unsub_count: string | null;
  sub_count: string | null;
  store_id: string | null;
};

export type KlaviyoSMSMetrics = {
  id: number;
  sms_date: string | null;
  click_count: string | null;
  received_count: string | null;
  unsub_count: string | null;
  sub_count: string | null;
  store_id: string | null;
};

export type KlaviyoDbFlowInfo = {
  id: number;
  flow_date: string;
  flow_id: string;
  name: string;
  status: string;
  trigger_type: string;
  store_id: string;
};

export type KlaviyoDbCampaignsFlowRevenueInfo = {
  id: number;
  cf_date: string;
  flow_campaign_id: string;
  type: string;
  sum_value_by_date: string;
  store_id: string;
};

export type KlaviyoDbFlowMetricsInfo = {
  id: number;
  f_date: string;
  flow_id: string;
  type: string;
  count_value_by_date: string;
  store_id: string;
};

export type KlaviyFlowSpamMetricsInfo = {
  id: number;
  fs_date: string;
  flow_id: string;
  count_value_by_date: string;
  store_id: string;
};

export type KlaviyCampaignSpamMetricsInfo = {
  id: number;
  cs_date: string;
  campaign_id: string;
  count_value_by_date: string;
  store_id: string;
};

type PeriodCardsDataKeys =
  | "klaviyoPeriodClicksAPI"
  | "klaviyoPeriodReceivedAPI"
  | "klaviyoPeriodUnsubsAPI"
  | "klaviyoPeriodFlowRevenueAPI"
  | "klaviyoPeriodCampaignRevenueAPI"
  | "klaviyoPeriodConversionAPI"
  | "klaviyoPeriodSubscribedAPI";

export type PeriodCardData = {
  [key in PeriodCardsDataKeys]: KlaviyoDbCampaignsFlowRevenueInfo[] | null;
} & {
  countMetrics: KlaviyoCountMetrics[] | null;
  smsMetrics: KlaviyoCountMetrics[] | null;
};

export enum KlaviyoCardsKeys {
  unsubscribed,
  totalClickedEmail,
  totalReceivedEmail,
  totalRevenue,
  flowRevenue,
  campaignRevenue,
  flowRevenuePerc,
  campaignRevenuePerc,
  netNewSubscribers,
  conversionRate,
  spamRate,

  // totalEmailRevenue,
  // totalSMSRevenue,
  totalClickedSMS,
  totalReceivedSMS,
  smsUnsubscibes,
  emailUnsubscibes,
  newNewEmailSubscribes,
  newNewSMSSubscibes,
}

export type KlaviyoCardPeriodValues = {
  [key in keyof typeof KlaviyoCardsKeys]: PeriodCardData;
};

export type KlaviyoCardValues = {
  [key in keyof typeof KlaviyoCardsKeys]: Omit<Card, "period_values">;
};

export type KlaviyoCards = {
  [key in keyof typeof KlaviyoCardsKeys]: Card | PinnedCard<KlaviyoCardsKeys>;
};

export type CampaignsPeriodInfo = {
  id: string;
  type: string;
};

export type KlaviyoFlowInfo = {
  id: string;
  attributes: {
    name: string;
    status: string;
    archived: boolean;
    created: string;
    updated: string;
    trigger_type: string;
  };
};

export type KlaviyoCampaignTableElement = {
  campaignName: string;
  status: string;
  sentAt: string;
  recipients: number;
  revenue: number;
  subject: string;
  type: string;
  unsubscribes: number | string;
  openRate: number | string;
  clickRate: number | string;
  spamRate: number | string;
  spamCount: number | string;
};

export type CampaignRecipientsAPI = {
  data: {
    type: string;
    id: string;
    attributes: {
      estimated_recipient_count: number;
    };
    links: {
      self: string;
    };
  };
};

export type KlaviyoBlockChart = {
  campaignName: string;
  value: number;
};

export type KlaviyoChartsData = {
  campaignsChart: Array<KlaviyoBlockChart>;
  flowsChart: Chart | undefined;
};

export type KlaviyoCardsChartsResponse = {
  cardsData: KlaviyoCards | undefined;
  chartsData: KlaviyoChartsData | undefined;
};

export type KlaviyoFlowTableElement = {
  flowName: string;
  status: string;
  count: number;
  orders: number;
  revenue: number;
  revenuePerRecipient: number | string;
  link: string;
  unsubscribed: number | string;
  triggerType: string;
  type: string;
  spamRate: number | string;
  spamCount: number | string;
};

export type KlaviyoTableData = {
  tableData: {
    campaignsTableData: KlaviyoCampaignTableElement[];
    flowsTableData: KlaviyoFlowTableElement[];
    summaryRow: {
      campaign: KlaviyoCampaignTableElement;
      flow: KlaviyoFlowTableElement;
    };
  } | null;
};
