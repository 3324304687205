import { IExtendedFilter } from "../hooks/useFiltering";

type ProductsDataParams = {
  storeId: string;
  customerId: string;
  managerCustomerId: string;
  platform?: string;

  from: string;
  to: string;
  currency: string;
  page: string | number;
  limit: string | number;
  sorting: { attribute: string; property: string };
  search: string;
  filter: IExtendedFilter[];
};

type ProductsSummaryParams = {
  storeId: string;
  from: string;
  to: string;
  currency: string;
  search: string;
  filter: IExtendedFilter[];
};

const ENDPOINTS: any = {
  productsData: ({
    storeId,
    from,
    to,
    currency,
    page,
    limit,
    sorting,
    search,
    filter,
    customerId,
    managerCustomerId,
  }: ProductsDataParams) => {
    const encodedFilter = encodeURIComponent(JSON.stringify(filter).replace(/%/g, ""));
    const attribute = encodeURI(sorting?.attribute.replace(/%/g, "%25"));
    return `/api/store/products?storeId=${storeId}&customerId=${customerId}&managerCustomerId=${managerCustomerId}&startDate=${from}&endDate=${to}&currency=${currency}&page=${page}&limit=${limit}&attrib=${attribute}&property=${sorting?.property}&searchByName=${search}&filter=${encodedFilter}`;
  },

  productsSummary: ({ storeId, from, to, currency, search, filter }: ProductsSummaryParams) => {
    const encodedFilter = encodeURIComponent(JSON.stringify(filter).replace(/%/g, ""));
    return `/api/store/products/summary?storeId=${storeId}&startDate=${from}&endDate=${to}&currency=${currency}&searchByName=${search}&filter=${encodedFilter}`;
  },
  exportProducts: ({
    storeId,
    from,
    to,
    platform,
    customerId,
    managerCustomerId,
  }: ProductsDataParams) =>
    `/api/store/products/export?storeId=${storeId}&platform=${platform}&customerId=${customerId}&managerCustomerId=${managerCustomerId}&startDate=${from}&endDate=${to}`,
};

export { ENDPOINTS };
