import React, { createContext, useState, useEffect, useContext } from "react";
import { useRouter } from "next/router";
import { ONBOARDING_INFO } from "../src/constansts/localStorage";
import { FormTypes } from "../src/pages/Onboarding/types/types";
import { useStoresContext } from "./StoresContext";

export type OnboardingData = Partial<
  FormTypes & {
    storeId: string;
    maxStep: number;
    isNewStoreOnboardingAllowed: boolean;
  }
> | null;

interface OnboardingContextProps {
  children: React.ReactElement;
}

interface OnboardingStorageContextType {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onboardingData: OnboardingData;
  setOnboardingInfo: (data: OnboardingData) => void;
  removeOnboardingInfo: () => void;
}

const OnboardingStorageContext = createContext<OnboardingStorageContextType | undefined>(undefined);

function OnboardingStorageProvider({ children }: OnboardingContextProps) {
  const { stores } = useStoresContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<OnboardingData>(null);

  const router = useRouter();
  useEffect(() => {
    if (!router.asPath.includes("/onboarding") && data && stores.length > 0) {
      removeOnboardingData();
    }
  }, [data, router.asPath, stores.length]);

  useEffect(() => {
    const storedData: OnboardingData = JSON.parse(localStorage.getItem(ONBOARDING_INFO) || "null");
    setData(storedData);
    setIsLoading(false);
  }, []);

  const setOnboardingData = React.useCallback((newData: OnboardingData) => {
    if (newData) {
      const result = newData || null;
      localStorage.setItem(ONBOARDING_INFO, JSON.stringify(result));
      setData(result);
    }
  }, []);

  const removeOnboardingData = () => {
    localStorage.removeItem(ONBOARDING_INFO);
    setData(null);
  };

  const contextValue: OnboardingStorageContextType = React.useMemo(
    () => ({
      onboardingData: data,
      setOnboardingInfo: setOnboardingData,
      removeOnboardingInfo: removeOnboardingData,
      isLoading,
      setIsLoading,
    }),
    [data, isLoading, setOnboardingData],
  );

  return (
    <OnboardingStorageContext.Provider value={contextValue}>
      {children}
    </OnboardingStorageContext.Provider>
  );
}

const useOnboardingStorageContext = (): OnboardingStorageContextType => {
  const context = useContext(OnboardingStorageContext);

  if (!context) {
    throw new Error("useOnboardingContext must be used within an OnboardingStorageProvider");
  }
  return context as OnboardingStorageContextType;
};

export { OnboardingStorageContext, OnboardingStorageProvider, useOnboardingStorageContext };
