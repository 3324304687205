import { ReactNode, createContext, useContext, useMemo, useState } from "react";

type Data = {
  response: any;
  isLoading: boolean;
  isLoadingPartnerId: boolean;
  partnerId: string | null;
  isSuccess: boolean;
};
type UseWhiteLabelReturn = {
  data: Data;
  hasPoweredByLogo?: boolean;
  logo: {
    fullSizeLight: string;
    fullSizeDark: string;
    smallSize: string;
    smallSizeFavicon: string;
  };
  setData: React.Dispatch<React.SetStateAction<Data>>;
};

export const WhitelabelingContext = createContext<UseWhiteLabelReturn | null>(null);

export const useWhitelabeling = (): UseWhiteLabelReturn => {
  const useAdvancedSettingsData = useContext(WhitelabelingContext);

  if (!useAdvancedSettingsData) {
    throw Error("Cannot use WhitelabelingContext outside of WhiteLabelingProvider");
  } else {
    return useAdvancedSettingsData;
  }
};

function WhiteLabelingProvider({ children }: { children: ReactNode }) {
  const [data, setData] = useState<Data>({
    isLoadingPartnerId: true,
    response: null,
    isLoading: true,
    partnerId: null,
    isSuccess: false,
  });

  const logo = useMemo(
    () => ({
      fullSizeLight: data?.response?.logo?.fullSize || "/staticfiles/Storehero-Logo-white.svg",
      fullSizeDark: data?.response?.logo?.fullSize || "/staticfiles/icons/logoPrimary.svg",
      smallSize: data?.response?.logo?.smallSize || "/staticfiles/logoIcon.svg",
      smallSizeFavicon: data?.response?.logo?.smallSize || "/favicon.svg",
    }),
    [data?.response?.logo?.fullSize, data?.response?.logo?.smallSize],
  );

  const hasPoweredByLogo = data?.response?.logo?.fullSize && !data.partnerId;

  const value = useMemo(
    () => ({ data, logo, hasPoweredByLogo, setData }),
    [data, logo, hasPoweredByLogo, setData],
  );

  return <WhitelabelingContext.Provider value={value}>{children}</WhitelabelingContext.Provider>;
}

export default WhiteLabelingProvider;
