import "../../src/configureAmplify";
import db from "../aws/dynamodb";
import { RUTTER_CONNECTIONS_TABLE_NAME } from "../constants";

export type ConnectedMarketingChannels = {
  isShopifyStoreConnected: boolean;
  isWoocommerceStoreConnected: boolean;
  isMagentoStoreConnected: boolean;
  isBigCommerceStoreConnected: boolean;
};

export async function getConnectedStores(storeId: string): Promise<ConnectedMarketingChannels> {
  const { Item } = await db.get({
    TableName: RUTTER_CONNECTIONS_TABLE_NAME,
    Key: {
      storeId,
    },
  });

  return {
    isShopifyStoreConnected: Item?.shopifyAccessToken !== undefined,
    isWoocommerceStoreConnected: Item?.woocommerceAccessToken !== undefined,
    isMagentoStoreConnected: Item?.magentoAccessToken !== undefined,
    isBigCommerceStoreConnected: Item?.bigCommerceAccessToken !== undefined,
  };
}

export async function getConnectedStoreAccessToken(storeId: string): Promise<string | null> {
  const { Item } = await db.get({
    TableName: RUTTER_CONNECTIONS_TABLE_NAME,
    Key: {
      storeId,
    },
  });

  if (Item?.shopifyAccessToken) {
    return Item.shopifyAccessToken;
  }

  if (Item?.woocommerceAccessToken) {
    return Item.woocommerceAccessToken;
  }

  if (Item?.magentoAccessToken) {
    return Item.magentoAccessToken;
  }

  if (Item?.bigCommerceAccessToken) {
    return Item.bigCommerceAccessToken;
  }

  return null;
}
