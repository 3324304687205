export enum MixpanelEvents {
  USER_SIGNUP = 'User signed up',
  ONBOARDING_COMPLETE = 'Onbording completed',
  PAGE_VISIT = 'Page visited',
  CONNECT_WITH_CHANNEL = 'Connected with channels',
  DATE_RANGE = 'Date range used',
  ONBOARDING_STEP = 'Onboarding step',
}

export enum Pages {
  AdvertisingCreativeOverview = 'Advertising - Creative Overview',
  AdvertisingFacebook = 'Advertising - Facebook',
  AdvertisingGoogle = 'Advertising - Google',
  AdvertisingOverview = 'Advertising - Overview',
  AdvertisingTiktok = 'Advertising - TikTok',
  Benchmarks = 'Benchmarks',
  Dashboard = 'Dashboard',
  Seo = 'Seo',
  Settings = 'Settings',
  EmailKlaviyo = 'Email - Klaviyo',
  StoreCohorts = 'Store - Cohorts',
  StoreGoogleAnalytics = 'Store - Google Analytics',
  StoreOnlineStore = 'Store - Online Store',
  StoreOrders = 'Store - Orders',
  StoreOverview = 'Store - Overview',
  StoreProducts = 'Store - Products',
  StoreSettings = 'Store - Settings',
  Finance = 'Finance',
  Alerts = 'Alerts',
  StoreGoogleAnalytics4 = 'Store - Google Analytics 4',
  RetentionOverview = 'Retention Overview',
}
