import { FlagType } from "../../lib/feature-flags/flags";

const ENDPOINTS = {
  getFeatureFlag: (storeId: string, flag: FlagType) =>
    `/api/feature-flags?storeId=${storeId}&flag=${flag}`,
};

const QUERY_KEYS = {
  FEATURE_FLAG: "FEATURE_FLAG",
};

export { ENDPOINTS, QUERY_KEYS };
