import { useMutation, useQuery, useQueryClient } from "react-query";
import api, { queryFetcher } from "../../src/APIResolvers";
import { useStoresContext } from "../StoresContext";
import { UpdateOnboardingChecklistItemManualOverrideArgs } from "../../lib/db/onboarding-checklist";

export type TUpdateOnboardingChecklist = {
  areAllMarkedAsComplete: boolean;
};

export type UpdateOnboardingChecklistItemManual = Omit<
  UpdateOnboardingChecklistItemManualOverrideArgs,
  "storeId"
>;

export interface IOnboardingChecklistResponse {
  areBasicsCompleted: boolean;
  areProductCostsCompleted: boolean;
  areShippingCostsCompleted: boolean;
  areFulfillmentCostsCompleted: boolean;
  areTransactionFeesCompleted: boolean;
}

export interface IOnboardingAllMarkedChecklist {
  allMarkedAsComplete: boolean;
}

export function useOnboardingChecklistQuery() {
  const { selectedStore } = useStoresContext();
  const queryClient = useQueryClient();
  const {
    data: onboardingChecklist,
    error: onboardingChecklistError,
    isLoading: onboardingChecklistLoading,
  } = useQuery(
    ["onboardingChecklist", selectedStore?.id],
    () => queryFetcher(api.onboardingСhecklist.ENDPOINTS.onboardingChecklist(selectedStore?.id)),
    { enabled: !!selectedStore?.id },
  );

  const { mutateAsync: addOnboardingAllItemsChecklist } = useMutation(
    (values: TUpdateOnboardingChecklist) =>
      queryFetcher(api.onboardingСhecklist.ENDPOINTS.onboardingChecklist(selectedStore?.id), {
        method: "POST",
        body: JSON.stringify({
          storeId: selectedStore?.id,
          ...values,
        }),
      }),
    {
      onSettled: () => queryClient.invalidateQueries(["onboardingChecklist", selectedStore?.id]),
    },
  );

  const { mutateAsync: addOnboardingItemChecklistManualOverride } = useMutation(
    (values: UpdateOnboardingChecklistItemManual) =>
      queryFetcher(
        api.onboardingСhecklist.ENDPOINTS.onboardingChecklistManualOverride(selectedStore?.id),
        {
          method: "POST",
          body: JSON.stringify({
            storeId: selectedStore?.id,
            ...values,
          }),
        },
      ),
    {
      onSettled: () => queryClient.invalidateQueries(["onboardingChecklist", selectedStore?.id]),
    },
  );

  return {
    onboardingChecklist,
    onboardingChecklistError,
    onboardingChecklistLoading,
    addOnboardingAllItemsChecklist,
    addOnboardingItemChecklistManualOverride,
  };
}
