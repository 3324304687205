import { AppSyncOnboardingOptionsItem } from "../../types";
import db from "../aws/dynamodb";
import { CURRENCY_TABLE_NAME, INDUSTRY_TABLE_NAME, ROLE_TABLE_NAME } from "../constants";

export async function getCurrency(
  currencyId: string,
): Promise<AppSyncOnboardingOptionsItem | undefined | null> {
  try {
    const { Item } = await db.get({
      TableName: CURRENCY_TABLE_NAME,
      Key: {
        id: currencyId,
      },
    });

    return Item as AppSyncOnboardingOptionsItem | undefined;
  } catch (error) {
    console.log("Get currency error ", error);
    return null;
  }
}

export async function getIndustry(industryId: string) {
  if (!industryId) return null;

  try {
    const { Item } = await db.get({
      TableName: INDUSTRY_TABLE_NAME,
      Key: {
        id: industryId,
      },
    });

    return Item;
  } catch (error) {
    console.log("Get industry error ", error);
    return null;
  }
}

export async function getRole(roleId: string) {
  if (!roleId) return null;
  try {
    const { Item } = await db.get({
      TableName: ROLE_TABLE_NAME,
      Key: {
        id: roleId,
      },
    });

    return Item;
  } catch (error) {
    console.log("Get role error ", error);
    return null;
  }
}
