import db from "../aws/dynamodb";
import { ACCESS_TOKENS_TABLE_NAME, TOKENS_TABLE_NAME } from "../constants";
import { getLongLivedToken } from "../facebook/ads";
import { CustomError, ErrorType } from "../errorTypes";
import { MetaTokensDB } from "./meta-auth";

export type FbToken = string | null | { customError: CustomError };

export type GoogleTokens = {
  googleAccessToken: string;
  googleAccessTokenExpires: number;
  googleRefreshToken: string;
};

const TokensTableName = TOKENS_TABLE_NAME;

async function getItem(userId: string) {
  const data = await db.get({
    TableName: TokensTableName,
    Key: {
      userId,
    },
  });

  return data.Item;
}

async function getGoogleAccessTokenByStoreId(storeId: string) {
  const { Item } = await db.get({
    TableName: ACCESS_TOKENS_TABLE_NAME,
    Key: {
      storeId,
    },
    ProjectionExpression: "googleAccessToken, googleAccessTokenExpires, googleRefreshToken",
  });

  return Item;
}

async function getFacebookAccessTokenByStoreId(storeId: string) {
  const { Item } = await db.get({
    TableName: ACCESS_TOKENS_TABLE_NAME,
    Key: {
      storeId,
    },
    ProjectionExpression: "facebookLongLivedAccessToken, facebookTokenExpiresAt",
  });

  return Item;
}

export async function saveGoogleTokens({
  googleTokens,
  username,
  storeId,
}: {
  googleTokens: GoogleTokens;
  username: string;
  storeId?: string;
}) {
  const { googleAccessToken, googleAccessTokenExpires, googleRefreshToken } = googleTokens;

  //if storeId !== undefined, then we are saving tokens for a store into the ACCESS_TOKENS_TABLE_NAME table. Otherwise - into the TOKENS_TABLE_NAME table
  return db.update({
    TableName: storeId ? ACCESS_TOKENS_TABLE_NAME : TokensTableName,
    Key: storeId ? { storeId } : { userId: username },
    UpdateExpression: `set googleAccessToken = :accessToken, googleAccessTokenExpires = :exp, googleRefreshToken = :ref, updatedAt = :upd ${
      storeId ? ", username = :username" : ""
    }`,
    ExpressionAttributeValues: {
      ":accessToken": googleAccessToken,
      ":exp": googleAccessTokenExpires,
      ":ref": googleRefreshToken,
      ":upd": new Date().toISOString(),
      ...(storeId && { ":username": username }),
    },
    ReturnValues: "UPDATED_NEW",
  });
}

async function getRefreshedTokens(refreshToken: string) {
  const url = `https://oauth2.googleapis.com/token?${new URLSearchParams({
    client_id: process.env.GOOGLE_CLIENT_ID || "",
    client_secret: process.env.GOOGLE_CLIENT_SECRET || "",
    grant_type: "refresh_token",
    refresh_token: refreshToken,
  })}`;

  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
  });

  const refreshedTokens = await response.json();

  if (!response.ok) {
    return null;
  }

  return refreshedTokens;
}

async function getRefreshedAccessToken(
  userId: string,
  refreshToken: string,
  storeId?: string,
): Promise<string | null> {
  //refresh token
  const refreshedTokens = await getRefreshedTokens(refreshToken);
  if (refreshedTokens) {
    const googleAccessToken = refreshedTokens.access_token;
    const googleAccessTokenExpires = Date.now() + refreshedTokens.expires_in * 1000;
    const updatedRefreshToken = refreshedTokens.refresh_token ?? refreshToken;
    // update tokens in the db
    saveGoogleTokens({
      username: userId,
      googleTokens: {
        googleAccessToken,
        googleAccessTokenExpires,
        googleRefreshToken: updatedRefreshToken,
      },
      storeId,
    });
    return googleAccessToken;
  }
  return null;
}

export async function getGoogleAccessToken(
  userId: string,
  storeId?: string,
): Promise<string | null> {
  let googleTokensData;
  if (storeId && storeId !== "undefined") {
    googleTokensData = await getGoogleAccessTokenByStoreId(storeId);
  }

  if (!googleTokensData || !googleTokensData.googleAccessToken) {
    googleTokensData = await getItem(userId);
  }

  const currentAccessToken = googleTokensData?.googleAccessToken;
  const expiresAt = googleTokensData?.googleAccessTokenExpires;

  if (expiresAt && Date.now() < (expiresAt as number)) {
    return currentAccessToken;
  }

  return getRefreshedAccessToken(userId, googleTokensData?.googleRefreshToken, storeId);
}

export async function saveFacebookTokens({
  accessToken,
  username,
  storeId,
}: {
  accessToken: string;
  username: string;
  storeId?: string;
}) {
  const { longLivedAccessToken, expiresAt } = await getLongLivedToken(accessToken);

  try {
    return await db.update({
      TableName: storeId ? ACCESS_TOKENS_TABLE_NAME : TokensTableName,
      Key: storeId ? { storeId } : { userId: username },
      UpdateExpression: `set facebookLongLivedAccessToken = :accessToken, facebookTokenExpiresAt = :exp, updatedAt = :upd ${
        storeId ? ", username = :username" : ""
      }`,
      ExpressionAttributeValues: {
        ":accessToken": longLivedAccessToken,
        ":exp": expiresAt || "never",
        ":upd": new Date().toISOString(),
        ...(storeId && { ":username": username }),
      },
      ReturnValues: "UPDATED_NEW",
    });
  } catch (e) {
    if (e instanceof Error) {
      console.log("ERROR SAVING FACEBOOK TOKENS", e.message);
    }
  }
}

export async function getFacebookAccessToken(
  userId: string,
  storeId?: string,
): Promise<string | null | { customError: CustomError }> {
  let fbTokensData;
  if (storeId && storeId !== "undefined") {
    fbTokensData = await getFacebookAccessTokenByStoreId(storeId);
  }
  if (!fbTokensData || !fbTokensData.facebookLongLivedAccessToken) {
    fbTokensData = await getItem(userId);
  }

  const accessToken = fbTokensData?.facebookLongLivedAccessToken;
  const expiresAt = fbTokensData?.facebookTokenExpiresAt;

  if (expiresAt === "never" && accessToken) {
    return accessToken;
  }

  if (expiresAt && Date.now() < expiresAt) {
    return accessToken;
  }
  if (expiresAt && Date.now() > expiresAt) {
    return {
      customError: {
        errorType: ErrorType.ACCESS_TOKEN_EXPIRED,
        message: `Meta ads access token expired on ${new Date(expiresAt).toLocaleString()}`,
      },
    };
  }

  return null;
}

export async function saveKlaviyoApiKey({
  username,
  apiKey,
}: {
  username: string;
  apiKey: string;
}) {
  const dbItem = await getItem(username);

  if (dbItem) {
    return db.update({
      TableName: TokensTableName,
      Key: {
        userId: username,
      },
      UpdateExpression: "set klaviyoApiKey = :apikey",
      ExpressionAttributeValues: {
        ":apikey": apiKey,
      },
      ReturnValues: "UPDATED_NEW",
    });
  }

  const item = {
    userId: username,
    klaviyoApiKey: apiKey,
    createdAt: new Date().toISOString(),
  };

  await db.put({ TableName: TokensTableName, Item: item });
}

export async function getKlaviyoApiKey(userId: string): Promise<string | undefined> {
  const dbItem = await getItem(userId);
  const apiKey: string | undefined = dbItem?.klaviyoApiKey;

  return apiKey;
}

const TIKTOK_BASE_URL = "https://business-api.tiktok.com/open_api/v1.3/";

export type TikTokAccount = {
  name: string;
  advertiser_id: string;
};

type TikTokAccountAPI = {
  data: {
    list: TikTokAccount[];
  };
};

async function getTikTokAccountNames(advertiserIds: (number | string)[], accessToken: string) {
  if (!advertiserIds || advertiserIds.length === 0) {
    return [];
  }
  const searchParams = new URLSearchParams({
    advertiser_ids: `[${advertiserIds.map((id) => `"${id}"`)}]`,
    fields: '["name", "advertiser_id"]',
  });

  const accountNames = await fetch(`${TIKTOK_BASE_URL}advertiser/info/?${searchParams}`, {
    headers: {
      "Access-Token": accessToken,
    },
  }).then((res) => res.json() as Promise<TikTokAccountAPI>);

  return accountNames.data.list;
}

async function getTikTokAccountData(code: string) {
  const appId = process.env.TIKTOK_APP_ID;
  const apiSecret = process.env.TIKTOK_API_SECRET;
  const response = await fetch(`${TIKTOK_BASE_URL}oauth2/access_token/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      app_id: appId,
      auth_code: code,
      secret: apiSecret,
    }),
  }).then((res) => res.json());

  if (!response.data) {
    throw new Error(response.message);
  }

  return {
    accessToken: response.data.access_token,
    advertiserIds: response.data.advertiser_ids,
  };
}

export async function saveTikTokToken({
  code,
  username,
  storeId,
}: {
  code: string;
  username: string;
  storeId?: string;
}) {
  const { accessToken, advertiserIds } = await getTikTokAccountData(code);

  return db.update({
    TableName: storeId ? ACCESS_TOKENS_TABLE_NAME : TokensTableName,
    Key: storeId ? { storeId } : { userId: username },
    UpdateExpression: `set tiktokLongLivedAccessToken = :accessToken, tiktokTokenCreatedAt = :upd, tiktokAdvertiserIds = :advertiserIds ${
      storeId ? ", username = :username" : ""
    }`,
    ExpressionAttributeValues: {
      ":accessToken": accessToken,
      ":upd": new Date().toISOString(),
      ":advertiserIds": advertiserIds,
      ...(storeId && { ":username": username }),
    },
    ReturnValues: "UPDATED_NEW",
  });
}

async function getTiktokAccessTokenByStoreId(storeId: string) {
  const { Item } = await db.get({
    TableName: ACCESS_TOKENS_TABLE_NAME,
    Key: {
      storeId,
    },
    AttributesToGet: ["tiktokLongLivedAccessToken"],
  });

  return Item?.tiktokLongLivedAccessToken;
}

async function getTiktokAccesstiktokAdvertiserIdsStoreId(storeId: string) {
  const { Item } = await db.get({
    TableName: ACCESS_TOKENS_TABLE_NAME,
    Key: {
      storeId,
    },
    AttributesToGet: ["tiktokAdvertiserIds"],
  });

  return Item?.tiktokAdvertiserIds;
}

export async function getTikTokAccessToken(userId: string, storeId?: string) {
  let tiktokLongLivedAccessToken;
  if (storeId && storeId !== "undefined") {
    tiktokLongLivedAccessToken = await getTiktokAccessTokenByStoreId(storeId);
  }

  if (!tiktokLongLivedAccessToken) {
    const dbItem = await getItem(userId);
    tiktokLongLivedAccessToken = dbItem?.tiktokLongLivedAccessToken;
  }

  return tiktokLongLivedAccessToken;
}

export async function getTikTokAdvertiserIds(userId: string, storeId?: string) {
  let tiktokAdvertiserIds;
  if (storeId && storeId !== "undefined") {
    tiktokAdvertiserIds = await getTiktokAccesstiktokAdvertiserIdsStoreId(storeId);
  }

  if (!tiktokAdvertiserIds) {
    const dbItem = await getItem(userId);
    tiktokAdvertiserIds = dbItem?.tiktokAdvertiserIds;
  }

  const tiktokAccessToken = await getTikTokAccessToken(userId, storeId);

  return getTikTokAccountNames(tiktokAdvertiserIds, tiktokAccessToken);
}

export async function deleteAccessTokens(storeId: string) {
  try {
    await db.delete({
      TableName: ACCESS_TOKENS_TABLE_NAME,
      Key: {
        storeId,
      },
    });
    return `Deleted access tokens for storeId: ${storeId}`;
  } catch (e) {
    console.log("ERROR DELETING ACCESS TOKENS: ", e);
    return `Error deleting access tokens for storeId: ${storeId}`;
  }
}

export async function getGoogleAndFacebookTokens(storeId: string) {
  if (!storeId || storeId === "undefined") return null;

  const { Item } = await db.get({
    TableName: ACCESS_TOKENS_TABLE_NAME,
    Key: {
      storeId,
    },
    ProjectionExpression:
      "googleAccessToken, googleAccessTokenExpires, googleRefreshToken, facebookLongLivedAccessToken, facebookTokenExpiresAt",
  });

  return Item as (MetaTokensDB & GoogleTokens) | undefined;
}
