import React from "react";
import toast from "react-hot-toast";
import { signOut } from "next-auth/client";
import {
  AuthUser,
  getCurrentUser,
  FetchUserAttributesOutput,
  signIn,
  signOut as signOutAmplify,
  fetchAuthSession,
  fetchUserAttributes,
} from "aws-amplify/auth";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/browser";
import "../src/configureAmplify";
import { MixpanelTracking } from "../src/services/MixpanelService";
import { useProfitWell } from "../src/hooks/useProfitWell";
import { ONBOARDING_INFO } from "../src/constansts/localStorage";

interface AuthContextValue {
  userAttributes: FetchUserAttributesOutput | null;
  authedUser: AuthUser | null;
  login: (payload: { username: string; password: string }, shopifyShop?: string) => Promise<void>;
  logout: () => void;
  [key: string]: any;
  authedUserLoading: boolean;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = React.createContext<AuthContextValue>({
  userAttributes: {},
  authedUser: null,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  authedUserLoading: true,
});

function AuthProvider(props: AuthProviderProps) {
  const { children } = props;
  const [authedUser, setAuthedUser] = React.useState<AuthUser | null>(null);
  const [userAttributes, setUserAttributes] = React.useState<FetchUserAttributesOutput | null>(
    null,
  );
  const router = useRouter();
  const [authedUserLoading, setAuthedUserLoading] = React.useState<boolean>(true);

  Sentry.setUser({ email: userAttributes?.email });

  React.useEffect(() => {
    setAuthedUserLoading(true);
    fetchAuthSession()
      .then((session) => {
        if (session && session.tokens) {
          getCurrentUser().then((user) => {
            setAuthedUser(user);
          });

          fetchUserAttributes().then((attributes) => {
            setUserAttributes(attributes);
          });
        }
      })
      .finally(() => {
        setAuthedUserLoading(false);
      });
  }, []);

  const userEmail = React.useMemo(() => userAttributes?.email, [userAttributes?.email]);
  const userSub = React.useMemo(() => userAttributes?.sub, [userAttributes?.sub]);

  React.useEffect(() => {
    if (userSub) {
      MixpanelTracking.getInstance().identifyNewUser(userSub);
    }
  }, [authedUser, userSub]);

  useProfitWell(userEmail);

  const login = React.useCallback(
    async (
      payload: { username: string; password: string },
      shopifyShop?: string,
    ): Promise<void> => {
      try {
        const { isSignedIn, nextStep } = await signIn(payload);
        if (isSignedIn) {
          const user = await getCurrentUser();
          setAuthedUser(user);
          const attributes = await fetchUserAttributes();
          setUserAttributes(attributes);
          toast.success("Login successful!");
          await router.push(`${shopifyShop ? `/onboarding?shop=${shopifyShop}` : "/"}`);
        }
        if (!isSignedIn && nextStep.signInStep === "CONFIRM_SIGN_UP") {
          await router.push(
            {
              pathname: "/register",
              query: { isVerify: true, username: payload.username },
            },
            "/register",
          );
        }
      } catch (e) {
        toast.error("Incorrect email or password!");
      }
    },
    [router],
  );

  const logout = React.useCallback(async () => {
    await signOutAmplify();
    setAuthedUser(null);
    localStorage.removeItem("store-id");
    localStorage.removeItem(ONBOARDING_INFO);
    await router.push("/login");
    await signOut();
    MixpanelTracking.getInstance().resetSession();
  }, [router]);

  const value = React.useMemo(
    () => ({
      login,
      logout,
      userAttributes,
      authedUser,
      authedUserLoading,
    }),
    [login, logout, userAttributes, authedUser, authedUserLoading],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuthContext() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useUser must be used within an UserProvider");
  }
  return context;
}

export { AuthProvider, useAuthContext, AuthContext };
