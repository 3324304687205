import { useEffect } from 'react';

const profitWellScript = `
    (function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
    a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
    s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
    })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');

    profitwell('start', { 'user_email': 'USER_EMAIL_HERE' }); 
`;

const publicApiToken = "3240935be5c764fd27612fcc3b98f25f";

export const useProfitWell = (email: string | undefined) => {
    useEffect(() => {
        if(email) {
            const script = document.createElement('script');
            script.id = 'profitwell-js';
            script.setAttribute('data-pw-auth', publicApiToken);
            script.async = true;

            script.innerHTML = profitWellScript.replace('USER_EMAIL_HERE', email);
            document.body.appendChild(script);
        }
    }, [email]);
};